import React, { useContext } from 'react';
import WorkSpacesContext from 'contexts/WorkSpacesContext';
import { isTargetsApp } from 'isWorkSpace';
import { engagementPortalRouteBuilders } from 'navigation/portalRoutes/engagementPortalRoutes';
import targetAppTypeCheckers from 'screens/TargetsAppShow/targetAppTypeCheckers';

import NavSection from '../../NavSection';
import NavItem from '../../NavItem';
import PermissionGates from '../../../../components/PermissionGates';
import PERMISSIONS from '../../../../permissionDefinitions';
import { useLocation } from 'react-router-dom';
import PortalEntityNavItem from './PortalEntityNavItem';

const EntityLinks = ({ portal }: { portal: EngagementPortal }) => {
  const { entityLinks } = portal;

  return (
    <>
      {entityLinks.map((link) => (
        <PortalEntityNavItem
          entityId={link.entityId}
          label={link.navLabel}
          key={link.entityId}
          icon={'navigation-metrics'}
        />
      ))}
    </>
  );
};

const TargetsLink = ({ portal }: { portal: EngagementPortal }) => {
  const { allWorkSpaces } = useContext(WorkSpacesContext);
  const { targetsLink } = portal;
  if (!targetsLink.isEnabled) {
    return null;
  }

  const targetsApp = allWorkSpaces
    .filter(isTargetsApp)
    .filter(targetAppTypeCheckers.isConfiguredApp)
    .find((a) => a.id === targetsLink.targetAppId);

  if (!targetsApp) {
    return null;
  }

  return (
    <NavItem
      to={engagementPortalRouteBuilders(portal.urlSlug).targetManagerShow(
        targetsApp.dataset,
      )}
      label={targetsLink.navLabel}
      icon={'navigation-goals'}
    />
  );
};

const EngagementPortalNavSection = ({
  portal,
}: {
  portal: EngagementPortal;
}) => {
  const hasReports = portal.reportIds.length > 0;
  const hasScorecards = portal.scorecardIds.length > 0;
  const { pathname } = useLocation();

  return (
    <>
      <NavSection label="View">
        <>
          <NavItem
            to={`/${portal.urlSlug}`}
            isActiveOverride={pathname === `/${portal.urlSlug}`}
            label={'Home'}
            tooltip={'Home'}
            icon={'home'}
          />
          <EntityLinks portal={portal} />
          <NavItem
            to={engagementPortalRouteBuilders(portal.urlSlug).dashboardsIndex}
            label={'Dashboards'}
            tooltip={'Dashboards'}
            icon={'dashboard'}
            onClickEvent={'Navigation Sidebar - Clicked Dashboards'}
          />
          {hasReports && (
            <NavItem
              to={engagementPortalRouteBuilders(portal.urlSlug).reportsIndex}
              label={'Reports'}
              tooltip={'Reports'}
              icon={'find-in-page'}
              onClickEvent={'Navigation Sidebar - Clicked Reports'}
            />
          )}
          {hasScorecards && (
            <NavItem
              to={engagementPortalRouteBuilders(portal.urlSlug).scorecardsIndex}
              label={'KPI Lists'}
              tooltip={'KPI Lists'}
              icon={'navigation-goals'}
              onClickEvent={'Navigation Sidebar - Clicked KPI Lists'}
            />
          )}
          <TargetsLink portal={portal} />
        </>
      </NavSection>
      <PermissionGates.Has
        requiredPermission={PERMISSIONS.CONTENT_ACCESS.ADD_USERS_TO_PORTAL}
      >
        <NavSection label="Build">
          <NavItem
            to={engagementPortalRouteBuilders(portal.urlSlug).users}
            isActiveOverride={pathname.includes(`/${portal.urlSlug}/access`)}
            label={'Users'}
            icon={'group'}
            tooltip="Users"
          />
        </NavSection>
      </PermissionGates.Has>
    </>
  );
};

export default EngagementPortalNavSection;
