import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

import Row from 'components/Common/Row';
import Dropdown from 'components/Inputs/Dropdown';
import BoardsContext from 'contexts/BoardsContext';
import UsersContext from 'contexts/UsersContext';
import userTypeCheckers from 'contextProviders/SplashScreenProviders/UserAndAccountProviders/UsersProvider/userTypeCheckers';
import getIdentifier from 'getIdentifier';
import FormContent from 'kingpin/forms/FormContent';
import FormInput from 'kingpin/forms/FormInput';
import Button from 'kingpin/atoms/Button';
import TextInput from 'kingpin/atoms/TextInput';
import isPerformanceBoard from 'isPerformanceBoard';
import PortalFormContext from '../PortalFormContext';
import DatasetDefinitionsContext from '../../../../../contexts/DatasetDefinitionsContext';
import Typography from '../../../../atoms/Typography';
import IconPicker from '../../../../atoms/IconPicker';
import { NAV_ITEM_DEFAULT_ICON_COLOR } from '../../../NavItem';

const NavSignOffBoardForm = ({
  item,
  onSave,
  close,
  bonusProgram,
}: {
  item: PortalsNav.SignOffBoard | undefined;
  onSave: (item: PortalsNav.SignOffBoard) => void;
  close: () => void;
  bonusProgram: Scoring.BonusProgram;
}) => {
  const { boards } = useContext(BoardsContext);
  const { allUsers } = useContext(UsersContext);
  const performanceBoards = useMemo(() => {
    return boards.filter(isPerformanceBoard);
  }, [boards]);

  const [key] = useState<string>(
    item ? item.key : getIdentifier(undefined, true),
  );
  const [category, setCategory] = useState<string>(item ? item.category : '');
  const [label, setLabel] = useState<string>(item ? item.label : '');
  const [icon, setIcon] = useState<Kingpin.Icon | undefined>(
    item ? item.icon : undefined,
  );
  const [boardId, setBoardId] = useState<string | undefined>(
    item ? item.boardId : undefined,
  );
  const [signOff, setSignOff] = useState<{
    ownerIds: string[];
  }>(item ? item.signOff : { ownerIds: [] });
  const [draftItem, setDraftItem] = useState<
    PortalsNav.SignOffBoard | undefined
  >(item);

  useEffect(() => {
    if (label === '' || !boardId || signOff.ownerIds.length === 0) {
      setDraftItem(undefined);
      return;
    }

    setDraftItem({
      key,
      label,
      boardId,
      type: 'Sign Off Board',
      signOff,
      category,
      icon,
    });
  }, [icon, key, label, boardId, signOff, category]);

  const onSaveClicked = useCallback(() => {
    if (!draftItem) {
      return;
    }

    onSave(draftItem);
    close();
  }, [close, draftItem, onSave]);

  const boardOptions = useMemo((): DropdownOption[] => {
    return performanceBoards.map((r) => ({
      key: r.id,
      label: r.name,
      isSelected: boardId === r.id,
      onSelected: () => {
        setBoardId(r.id);
      },
    }));
  }, [boardId, performanceBoards]);

  const userOptions = useMemo((): DropdownOption[] => {
    const eligibleUsers = allUsers.filter(
      userTypeCheckers.isSignedUpOrPendingUser,
    );

    return eligibleUsers.map((user) => {
      const isSelected = signOff.ownerIds.includes(user.id);
      const newOwnerIds = isSelected
        ? signOff.ownerIds.filter((id) => id !== user.id)
        : [...signOff.ownerIds, user.id];
      return {
        key: user.id,
        label: user.displayName,
        onSelected: () => {
          setSignOff({
            ownerIds: newOwnerIds,
          });
        },
        isSelected,
      };
    });
  }, [allUsers, signOff]);

  const categoryOptions = useMemo(() => {
    return bonusProgram.categoryFields.map((c): DropdownOption => {
      return {
        label: c.name,
        isSelected: !!category && category === c.name,
        onSelected: () => {
          setCategory(c.name);
        },
      };
    });
  }, [bonusProgram, category]);

  return (
    <div>
      <FormContent>
        <FormInput label={'Label'}>
          <TextInput
            value={label}
            onChange={(event) => {
              setLabel(event.target.value);
            }}
          />
        </FormInput>
        <FormInput label={'Icon'}>
          <IconPicker
            setSelectedIcon={setIcon}
            selectedIcon={icon}
            iconColor={NAV_ITEM_DEFAULT_ICON_COLOR}
          />
        </FormInput>
        <FormInput label={'Board'}>
          <Dropdown options={boardOptions} />
        </FormInput>
        <FormInput label={'Bonus Category'}>
          <Dropdown options={categoryOptions} placeholder="Category" />
        </FormInput>
        <FormInput label={'Owners'}>
          <Dropdown options={userOptions} isMulti placeholder="Owned By" />
        </FormInput>
      </FormContent>
      <Row centerAlign spaceBetween>
        <Button
          size={'Small'}
          type={'Secondary'}
          label={'Cancel'}
          onClick={close}
        />
        <Button
          isDisabled={draftItem === undefined}
          onClick={onSaveClicked}
          label={item ? 'Update' : 'Add'}
          type={'Primary'}
          size={'Small'}
        />
      </Row>
    </div>
  );
};

const Gate = ({
  item,
  onSave,
  close,
}: {
  item: PortalsNav.SignOffBoard | undefined;
  onSave: (item: PortalsNav.SignOffBoard) => void;
  close: () => void;
}) => {
  const { bonusProgramId } = useContext(PortalFormContext);
  const { bonusPrograms } = useContext(DatasetDefinitionsContext);
  const bonusProgram = useMemo(() => {
    if (!bonusProgramId) {
      return undefined;
    }

    return bonusPrograms.find((p) => p.id === bonusProgramId);
  }, [bonusPrograms, bonusProgramId]);

  if (!bonusProgram) {
    return (
      <div>
        <Typography.Body type={'Body 13'}>
          Bonus program not found
        </Typography.Body>
      </div>
    );
  }

  return (
    <NavSignOffBoardForm
      item={item}
      onSave={onSave}
      close={close}
      bonusProgram={bonusProgram}
    />
  );
};

export default Gate;
