import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

import Row from 'components/Common/Row';
import Dropdown from 'components/Inputs/Dropdown';
import ReportsContext from 'contexts/ReportsContext';
import getIdentifier from 'getIdentifier';
import FormContent from 'kingpin/forms/FormContent';
import FormInput from 'kingpin/forms/FormInput';
import Button from 'kingpin/atoms/Button';
import TextInput from 'kingpin/atoms/TextInput';
import IconPicker from '../../../../atoms/IconPicker';
import { NAV_ITEM_DEFAULT_ICON_COLOR } from '../../../NavItem';

const NavReportForm = ({
  item,
  onSave,
  close,
}: {
  item: PortalsNav.Report | undefined;
  onSave: (item: PortalsNav.Report) => void;
  close: () => void;
}) => {
  const { allReports } = useContext(ReportsContext);

  const [key] = useState<string>(
    item ? item.key : getIdentifier(undefined, true),
  );
  const [icon, setIcon] = useState<Kingpin.Icon | undefined>(
    item ? item.icon : undefined,
  );
  const [label, setLabel] = useState<string>(item ? item.label : '');
  const [reportId, setReportId] = useState<string | undefined>(
    item ? item.reportId : undefined,
  );
  const [draftItem, setDraftItem] = useState<PortalsNav.Report | undefined>(
    item,
  );

  useEffect(() => {
    if (label === '' || !reportId) {
      setDraftItem(undefined);
      return;
    }

    setDraftItem({
      key,
      label,
      reportId,
      type: 'Report',
      icon,
    });
  }, [icon, key, label, reportId]);

  const onSaveClicked = useCallback(() => {
    if (!draftItem) {
      return;
    }

    onSave(draftItem);
    close();
  }, [close, draftItem, onSave]);

  const reportOptions = useMemo((): DropdownOption[] => {
    return allReports.map((r) => ({
      key: r.id,
      label: r.name,
      isSelected: reportId === r.id,
      onSelected: () => {
        setReportId(r.id);
      },
    }));
  }, [allReports, reportId]);

  return (
    <div>
      <FormContent>
        <FormInput label={'Label'}>
          <TextInput
            value={label}
            onChange={(event) => {
              setLabel(event.target.value);
            }}
          />
        </FormInput>
        <FormInput label={'Icon'}>
          <IconPicker
            setSelectedIcon={setIcon}
            selectedIcon={icon}
            iconColor={NAV_ITEM_DEFAULT_ICON_COLOR}
          />
        </FormInput>
        <FormInput label={'Report'}>
          <Dropdown options={reportOptions} />
        </FormInput>
      </FormContent>
      <Row centerAlign spaceBetween>
        <Button
          size={'Small'}
          type={'Secondary'}
          label={'Cancel'}
          onClick={close}
        />
        <Button
          isDisabled={draftItem === undefined}
          onClick={onSaveClicked}
          label={item ? 'Update' : 'Add'}
          type={'Primary'}
          size={'Small'}
        />
      </Row>
    </div>
  );
};

export default NavReportForm;
