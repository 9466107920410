import { DASHBOARDS_COLLECTION } from '../constants';

const VISUALISATIONS_COLLECTION = 'visualisations';
const METRIC_DEFINITIONS_COLLECTION = 'metricDefinitions';
const FILTER_DEFINITIONS_COLLECTION = 'filterDefinitions';
const ENTITY_DEFINITIONS_COLLECTION = 'entityDefinitions';
const SAVED_PROGRESSES_COLLECTION = 'savedProgresses';
const TIMELINE_EVENT_COLLECTION = 'timelineEvents';
const DATASETS_COLLECTION = 'datasets';
const CONTENT_DISTRIBUTIONS_COLLECTION = 'contentDistributions';
const REASON_CODES_COLLECTION = 'reasonCodes';
const USERS_COLLECTION = 'users';
const LEGACY_SAVED_FILTERS_COLLECTION = 'savedFilters';
const PREVIOUS_UPLOADS_COLLECTION = 'previousUploads';
const SCORING_COLLECTION = 'scoring';
export const COPY_CONTENT_RECEIPTS_COLLECTION = 'copyContentReceipts';

const STORE_CONSTANTS = {
  VISUALISATIONS_COLLECTION,
  METRIC_DEFINITIONS_COLLECTION,
  FILTER_DEFINITIONS_COLLECTION,
  ENTITY_DEFINITIONS_COLLECTION,
  TIMELINE_EVENT_COLLECTION,
  DATASETS_COLLECTION,
  DASHBOARDS_COLLECTION,
  SAVED_PROGRESSES_COLLECTION,
  CONTENT_DISTRIBUTIONS_COLLECTION,
  REASON_CODES_COLLECTION,
  USERS_COLLECTION,
  LEGACY_SAVED_FILTERS_COLLECTION,
  PREVIOUS_UPLOADS_COLLECTION,
  COPY_CONTENT_RECEIPTS_COLLECTION,
  SCORING_COLLECTION,
};

export default STORE_CONSTANTS;
