import React, { useContext, useEffect, useState } from 'react';
import useApps from './useApps';
import { PortalsContext } from '../PortalsProvider';
import portalTypeCheckers from '../../../../types/portalTypeCheckers';

export const AppsContext = React.createContext<{
  apps: Apps.App[];
  selectedApp?: Apps.App;
  setSelectedApp: React.Dispatch<React.SetStateAction<Apps.App | undefined>>;
  isLoadingApps: boolean;
  hasAppsEnabled: boolean;
}>({
  apps: [],
  setSelectedApp: () => {},
  isLoadingApps: true,
  hasAppsEnabled: false,
});

const AppsProvider = ({
  children,
}: {
  children: JSX.Element | JSX.Element[];
}) => {
  const { selectedPortal } = useContext(PortalsContext);
  const [selectedApp, setSelectedApp] = useState<Apps.App | undefined>();
  const { apps, isLoading, hasAppsEnabled } = useApps();

  useEffect(() => {
    if (selectedApp) {
      return;
    }

    if (!selectedPortal) {
      return;
    }

    const app = apps.find((app) => {
      if (portalTypeCheckers.isAdminPortal(selectedPortal)) {
        return false;
      }

      if (portalTypeCheckers.isExecutivePortal(selectedPortal)) {
        return app.type === 'Analytics';
      }

      return (
        app.type === 'General' &&
        app.portals.some((p) => p.id === selectedPortal.id)
      );
    });

    setSelectedApp(app);
  }, [apps, selectedApp, selectedPortal]);

  return (
    <AppsContext.Provider
      value={{
        apps,
        isLoadingApps: isLoading,
        hasAppsEnabled,
        selectedApp,
        setSelectedApp,
      }}
    >
      {children}
    </AppsContext.Provider>
  );
};

export default AppsProvider;
