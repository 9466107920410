import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import AccountContext from '../../../contexts/AccountContext';
import UNIT_LOCALES from '../../../propTypes/unitLocales';
import AccountPickerContext from '../../../contexts/AccountPickerContext';
import STORE from '../../../store';
import ProductTierProvider from './ProductTierProvider';

const DEMO_4_ACCOUNT_ID = 'qKpNbQjWgMIEaWpu';
const DEMO_5_ACCOUNT_ID = 'fopsUiEOdegTakJzDxWk';
const DEMO_6_ACCOUNT_ID = 'fopstBvqxkLZeqNvLTBM';
const ACME_ACCOUNT_ID = 'fopsRXVeqntlLLVXbOGm';
const SANDBOX_ACCOUNT_ID = 'fopsGfEtxXSRQKXyEqkN';
const DEMO_4_ACCOUNT_NOW = '2021-06-23';
const DEMO_5_ACCOUNT_NOW = '2021-11-19';
const DEMO_6_ACCOUNT_NOW = '2024-03-04';
const SANDBOX_ACCOUNT_NOW = '2023-06-23';
const ACME_ACCOUNT_NOW = '2024-03-04';

const DEFAULT_START_OF_WEEK = 'SUN';

function capitalizeFirstLetter(string: string) {
  if (!string) {
    return '';
  }
  return string.charAt(0).toUpperCase() + string.slice(1);
}

const AccountProvider = ({
  children,
}: {
  children: JSX.Element | JSX.Element[];
}) => {
  const { accountRef, selectedAccount, selectedAccountId } =
    useContext(AccountPickerContext);
  const [unitsLocale, setUnitsLocale] = useState<string>(UNIT_LOCALES.IMPERIAL);
  const [status, setStatus] = useState<
    'initialized' | 'pending' | 'active' | 'error' | undefined
  >(undefined);
  const [error, setError] = useState<string | undefined>(undefined);
  const [isLoadingCarrierName, setIsLoadingCarrierName] =
    useState<boolean>(true);
  const [timezone, setTimezone] = useState<string | undefined>();
  const [tier, setTier] = useState<ProductTier | undefined>();
  const [weekStartsOn, setWeekStartsOn] = useState<WeekStartsOn>(
    DEFAULT_START_OF_WEEK,
  );
  const [driverScoreDataSet, setDriverScoreDataSet] = useState<
    string | undefined
  >();
  const [isPendingManualAchVerification, setIsPendingManualAchVerification] =
    useState<boolean>(false);
  const [
    isPendingAutomaticAchVerification,
    setIsPendingAutomaticAchVerification,
  ] = useState<boolean>(false);
  const [automaticAchVerificationFailed, setAutomaticAchVerificationFailed] =
    useState<boolean>(false);
  const [achVerificationStartedOn, setAchVerificationStartedOn] = useState<
    string | undefined
  >();
  const [isTemplateAccount, setIsTemplateAccount] = useState<boolean>(false);
  const [isBillingSetUp, setIsBillingSetUp] = useState<boolean>(false);
  const [isIRSetUp, setIsIRSetUp] = useState<boolean>(false);
  const [configuredProviders, setConfiguredProviders] = useState<string[]>([]);
  const [tms, setTms] = useState<string | undefined>();
  const [adfRequired, setAdfRequired] = useState<boolean | undefined>();
  const [hasAppsEnabled, setHasAppsEnabled] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    STORE.getAccountRef({ accountId: selectedAccountId })
      .get()
      .then((doc) => {
        const data = doc.data();
        if (data) {
          const newStart = data.startOfWeek;
          if (newStart) {
            setWeekStartsOn(newStart);
          } else {
            setWeekStartsOn(DEFAULT_START_OF_WEEK);
          }
        }
      });
  }, [selectedAccountId]);

  useEffect(() => {
    STORE.getAccountRef({ accountId: selectedAccountId })
      .get()
      .then((doc) => {
        const account = doc.data();
        if (!account) {
          return;
        }
        setTier(account.tier);
        setTms(account.tms);
        setAdfRequired(account.adfRequired);
        setIsLoadingCarrierName(false);
        setIsPendingManualAchVerification(
          !!account.isPendingManualAchVerification,
        );
        setIsPendingAutomaticAchVerification(
          !!account.isPendingAutomaticAchVerification,
        );
        setAchVerificationStartedOn(account.achVerificationStartedOn);
        setAutomaticAchVerificationFailed(
          !!account.automaticAchVerificationFailed,
        );
        setStatus(account.status ? account.status : 'active');
        setTimezone(account.timeZone);
        setIsTemplateAccount(!!account.isTemplateAccount);
        setHasAppsEnabled(!!account.hasAppsEnabled);
        setIsLoading(false);

        const maybeL = account.unitsLocale;
        if (maybeL) {
          setUnitsLocale(maybeL);
        } else {
          setUnitsLocale(UNIT_LOCALES.METRIC);
        }
      });

    const l2 = STORE.getAccountRef({ accountId: selectedAccountId }).onSnapshot(
      (snapshot) => {
        const accountData = snapshot.data();
        if (!accountData) {
          return;
        }
        setStatus(accountData.status);
        setError(accountData.error);
        setIsPendingManualAchVerification(
          !!accountData.isPendingManualAchVerification,
        );
        setIsPendingAutomaticAchVerification(
          !!accountData.isPendingAutomaticAchVerification,
        );
        setAchVerificationStartedOn(accountData.achVerificationStartedOn);
        setAutomaticAchVerificationFailed(
          !!accountData.automaticAchVerificationFailed,
        );
        setHasAppsEnabled(!!accountData.hasAppsEnabled);
      },
    );
    return () => {
      l2();
    };
  }, [accountRef, selectedAccountId]);

  useEffect(() => {
    if (process.env.NODE_ENV === 'test') {
      return;
    }

    accountRef
      .collection('scoring')
      .doc('drivers')
      .get()
      .then((driverScoringConfigDoc) => {
        if (driverScoringConfigDoc.exists) {
          const data = driverScoringConfigDoc.data();
          if (!data) {
            return;
          }
          if (data.period) {
            const newDataSet = `driverScore${capitalizeFirstLetter(
              data.period,
            )}`;
            setDriverScoreDataSet(newDataSet);
          } else {
            setDriverScoreDataSet('driverScores');
          }
        }
      });
  }, [accountRef]);

  useEffect(() => {
    if (process.env.NODE_ENV === 'test') {
      return;
    }

    accountRef
      .collection('onBoarding')
      .doc('status')
      .onSnapshot((snap) => {
        if (snap.exists) {
          const data = snap.data();
          if (data) {
            setIsBillingSetUp(data.isBillingSetUp);
            setIsIRSetUp(!!data.isIRSetUp);
            setConfiguredProviders(
              data.configuredProviders || window.emptyArray,
            );
          }
        }
      });
  }, [accountRef]);

  const isDemoAccount4 = accountRef && accountRef.id === DEMO_4_ACCOUNT_ID;
  const isDemoAccount5 = accountRef && accountRef.id === DEMO_5_ACCOUNT_ID;
  const isDemoAccount6 = accountRef && accountRef.id === DEMO_6_ACCOUNT_ID;
  const isSandboxAccount = accountRef && accountRef.id === SANDBOX_ACCOUNT_ID;
  const isAcme = accountRef && accountRef.id === ACME_ACCOUNT_ID;

  return (
    <AccountContext.Provider
      value={{
        unitsLocale,
        id: selectedAccount.accountId,
        carrierName: selectedAccount.accountName,
        status,
        error,
        isLoadingCarrierName,
        tier,
        weekStartsOn,
        driverScoreDataSet,
        isPendingManualAchVerification,
        isPendingAutomaticAchVerification,
        automaticAchVerificationFailed,
        achVerificationStartedOn,
        tms,
        adfRequired,
        isBillingSetUp,
        isIRSetUp,
        configuredProviders,
        timezone,
        isTemplateAccount,
        isLoading,
        isAccountOnBoarded: status !== 'active',
        isDemoAccount:
          isDemoAccount4 ||
          isDemoAccount5 ||
          isDemoAccount6 ||
          isAcme ||
          isSandboxAccount,
        demoAccountNow: isDemoAccount4
          ? DEMO_4_ACCOUNT_NOW
          : isDemoAccount5
            ? DEMO_5_ACCOUNT_NOW
            : isDemoAccount6
              ? DEMO_6_ACCOUNT_NOW
              : isAcme
                ? ACME_ACCOUNT_NOW
                : isSandboxAccount
                  ? SANDBOX_ACCOUNT_NOW
                  : undefined,
        isDemo6: isDemoAccount6 || isAcme,
        hasAppsEnabled,
      }}
    >
      <ProductTierProvider>{children}</ProductTierProvider>
    </AccountContext.Provider>
  );
};

AccountProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

export default AccountProvider;
