import React, { useContext, useMemo } from 'react';
import Dropdown from '../../../../components/Inputs/Dropdown';
import NavItem, { NAV_ITEM_BOTTOM_MARGIN } from '../../NavItem';
import { useLocation } from 'react-router-dom';
import NavSection from '../../NavSection';
import BonusPeriodsContext from '../../../../contexts/BonusPeriodsContext';
import PortalEntityNavItem from './PortalEntityNavItem';
import getNavItemLink from './getNavItemLink';

const BonusPeriodPicker = () => {
  const { bonusPeriods, selectedBonusPeriod, setSelectedBonusPeriod } =
    useContext(BonusPeriodsContext);

  const options = useMemo((): DropdownOption[] => {
    return bonusPeriods.map((p) => ({
      key: p.id,
      label: p.label,
      isSelected: selectedBonusPeriod && selectedBonusPeriod.id === p.id,
      onSelected: () => {
        setSelectedBonusPeriod(p);
      },
    }));
  }, [bonusPeriods, selectedBonusPeriod, setSelectedBonusPeriod]);

  return <Dropdown options={options} />;
};

export const BonusNavItem = ({
  section,
  item,
  portal,
}: {
  section: PortalSideNavigationSection;
  item: PortalsNav.Item;
  portal: BonusPortal;
}) => {
  const { pathname } = useLocation();
  const to = getNavItemLink({
    item,
    section,
    portal,
  });

  if (item.type === 'Bonus Period Picker') {
    return (
      <div style={{ marginBottom: NAV_ITEM_BOTTOM_MARGIN }}>
        <BonusPeriodPicker />
      </div>
    );
  }

  if (item.type === 'Bonus Home') {
    return (
      <NavItem
        key={item.key}
        label={item.label}
        to={`/${portal.urlSlug}/${section.urlSlug}`}
        isActiveOverride={pathname === `/${portal.urlSlug}/${section.urlSlug}`}
        icon={item.icon}
      />
    );
  }

  if (item.type === 'Entity App') {
    return (
      <PortalEntityNavItem
        label={item.label}
        entityId={item.entityId}
        icon={item.icon}
      />
    );
  }

  return <NavItem key={item.key} label={item.label} to={to} icon={item.icon} />;
};

const BonusPortalNavSection = ({ portal }: { portal: BonusPortal }) => {
  return (
    <div style={{ flex: 1 }}>
      {portal.sideNavigationSections.map((section) => (
        <NavSection
          label={section.title.isEnabled ? section.title.label : undefined}
          hideBorderBottom={!section.isBottomBorderEnabled}
          key={section.key}
        >
          {section.items.map((item) => (
            <BonusNavItem
              portal={portal}
              item={item}
              key={item.key}
              section={section}
            />
          ))}
        </NavSection>
      ))}
    </div>
  );
};

export default BonusPortalNavSection;
