import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { PortalsContext } from 'contextProviders/SplashScreenProviders/UserAndAccountProviders/PortalsProvider';
import useEntity from 'hooks/useEntity';
import { buildEntityAppRedirect } from 'navigation/appRoutes';

import NavItem from '../../NavItem';

const PortalEntityNavItem = ({
  entity,
  label,
  icon,
  section,
}: {
  entity: EntityDetails.Entity;
  label: string;
  icon?: Kingpin.Icon;
  section?: PortalSideNavigationSection;
}) => {
  const { selectedPortal } = useContext(PortalsContext);

  const { pathname } = useLocation();
  const getIsActive = useCallback(() => {
    return (
      pathname.includes(`entities/${entity.primaryField}`) ||
      pathname.includes(`entity/${entity.id}`)
    );
  }, [pathname, entity.primaryField, entity.id]);
  const [isActive, setIsActive] = useState<boolean>(() => getIsActive());
  useEffect(() => {
    setIsActive(getIsActive());
  }, [getIsActive]);

  return (
    <NavItem
      testId={`${label}-ready`}
      to={buildEntityAppRedirect({
        portal: selectedPortal,
        entityId: entity.id,
        section,
      })}
      isActiveOverride={isActive}
      label={label}
      icon={icon}
    />
  );
};

const Gate = ({
  entityId,
  label,
  icon,
  section,
}: {
  entityId: string;
  label: string;
  icon?: Kingpin.Icon;
  section?: PortalSideNavigationSection;
}) => {
  const { entity } = useEntity(entityId);

  if (!entity) {
    return null;
  }

  return (
    <PortalEntityNavItem
      label={label}
      entity={entity}
      icon={icon}
      section={section}
    />
  );
};

export default Gate;
