import React, { useCallback, useContext, useState } from 'react';

import Form from 'kingpin/forms/Form';
import FormHeader from 'kingpin/forms/FormHeader';
import FormContent from 'kingpin/forms/FormContent';
import Button from 'kingpin/atoms/Button';
import TextInput from 'kingpin/atoms/TextInput';
import Row from 'components/Common/Row';
import Dropdown from 'components/Inputs/Dropdown';
import portalTypeCheckers from 'types/portalTypeCheckers';
import STORE from 'store';
import AccountPickerContext from 'contexts/AccountPickerContext';
import ToastContext from 'contexts/ToastContext';

import useFormState from './useFormState';
import ColorDot from '../ColorDot';
import EntityLinksInput from './EntityLinksInput';
import TargetLinkInput from './TargetLinkInput';
import BonusProgramPicker from './BonusProgramPicker';
import SideNavigationBuilder from './SideNavigationBuilder';
import FormInput from '../../../forms/FormInput';
import PortalFormContext from './PortalFormContext';
import DefaultScreenPicker from './DefaultScreenPicker';

const CreatePortalForm = ({
  close,
  portal,
}: {
  close: () => void;
  portal?: EditablePortal;
}) => {
  const { showToast } = useContext(ToastContext);
  const { selectedAccountId } = useContext(AccountPickerContext);

  const {
    name,
    onNameChanged,
    portalTypeOptions,
    colorOptions,
    draftPortal,
    color,
    onUrlSlugChanged,
    urlSlug,
    portalType,
    entityLinks,
    setEntityLinks,
    targetsLink,
    setTargetsLink,
    isUrlSlugValid,
    bonusProgramId,
    setBonusProgramId,
    sideNavigationSections,
    setSideNavigationSections,
    defaultScreen,
    setDefaultScreen,
  } = useFormState(portal);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const onSaveClicked = useCallback(() => {
    if (!draftPortal) {
      return;
    }
    setIsSaving(true);

    if (portalTypeCheckers.isExecutivePortal(draftPortal)) {
      STORE.contentDistributions
        .getExecutivePortalsRef({
          accountId: selectedAccountId,
        })
        .doc(draftPortal.id)
        .set(draftPortal)
        .then(() => {
          setIsSaving(false);
          showToast(
            `${name} Executive Portal has been ${portal ? 'updated' : 'created'}`,
          );
          close();
        });
    } else if (portalTypeCheckers.isEngagementPortal(draftPortal)) {
      STORE.contentDistributions
        .getEngagementPortalsRef({
          accountId: selectedAccountId,
        })
        .doc(draftPortal.id)
        .set(draftPortal)
        .then(() => {
          setIsSaving(false);
          showToast(
            `${name} Engagement Portal has been ${portal ? 'updated' : 'created'}`,
          );
          close();
        });
    } else if (portalTypeCheckers.isBonusPortal(draftPortal)) {
      STORE.contentDistributions
        .getBonusPortalsRef({ accountId: selectedAccountId })
        .doc(draftPortal.id)
        .set(draftPortal)
        .then(() => {
          setIsSaving(false);
          showToast(
            `${name} Bonus Portal has been ${portal ? 'updated' : 'created'}`,
          );
          close();
        });
    }
  }, [close, draftPortal, name, portal, selectedAccountId, showToast]);

  return (
    <PortalFormContext.Provider
      value={{
        bonusProgramId,
      }}
    >
      <Form>
        <FormHeader
          title={portal ? 'Edit Portal' : 'New Portal'}
          onClose={close}
        />
        <FormContent>
          {/* Name */}
          <FormInput label={'Name'}>
            <Row spaceBetween centerAlign>
              <div style={{ marginRight: 8, width: '100%' }}>
                <TextInput
                  value={name}
                  onChange={onNameChanged}
                  data-testid={'name-input'}
                />
              </div>

              <Dropdown
                options={colorOptions}
                renderSelectedLabel={() =>
                  color ? (
                    <ColorDot
                      color={color}
                      key={`selected-${color}`}
                      width={20}
                      height={20}
                    />
                  ) : (
                    <div />
                  )
                }
              />
            </Row>
          </FormInput>

          {/* Url slug */}
          <FormInput label={'Url Slug'}>
            <TextInput
              value={urlSlug}
              onChange={onUrlSlugChanged}
              data-testid={'url-slug-input'}
              state={urlSlug !== '' && !isUrlSlugValid ? 'Error' : undefined}
            />
          </FormInput>

          {/* Type */}
          <FormInput label={'Type'}>
            <Dropdown options={portalTypeOptions} />
          </FormInput>

          {portalType && portalType === 'Engagement Portal' && (
            <>
              {/* Entities */}
              <FormInput label={'Entities'}>
                <EntityLinksInput
                  entityLinks={entityLinks}
                  setEntityLinks={setEntityLinks}
                />
              </FormInput>

              {/* Targets */}
              <FormInput label={'Targets'}>
                <TargetLinkInput
                  targetsLink={targetsLink}
                  setTargetsLink={setTargetsLink}
                />
              </FormInput>
            </>
          )}
          {portalType && portalType === 'Bonus Portal' && (
            <>
              <FormInput label={'Bonus Program'}>
                <BonusProgramPicker
                  bonusProgramId={bonusProgramId}
                  setBonusProgramId={setBonusProgramId}
                />
              </FormInput>
              <FormInput label={'Side Navigation'}>
                <SideNavigationBuilder
                  sideNavigationSections={sideNavigationSections}
                  setSideNavigationSections={setSideNavigationSections}
                />
              </FormInput>
              <FormInput label={'Default Screen'}>
                <DefaultScreenPicker
                  defaultScreen={defaultScreen}
                  setDefaultScreen={setDefaultScreen}
                  sideNavigationSections={sideNavigationSections}
                />
              </FormInput>
            </>
          )}
        </FormContent>
        <Row spaceBetween>
          <Button
            size={'Small'}
            type={'Secondary'}
            label={'Cancel'}
            onClick={close}
            isDisabled={isSaving}
          />
          <Button
            size={'Small'}
            type={'Primary'}
            label={portal ? 'Update' : 'Create'}
            onClick={onSaveClicked}
            isLoading={isSaving}
            isDisabled={draftPortal === undefined || isSaving}
          />
        </Row>
      </Form>
    </PortalFormContext.Provider>
  );
};

export default CreatePortalForm;
