import {
  buildDashboardShow,
  buildDriverScoringProgramReportShow,
  buildEntityAppRedirect,
  buildReportShow,
  buildScoringProgramConfigShow,
  buildShowGlobalKpiList,
  buildShowSignOffBoard,
} from '../../../../navigation/appRoutes';

const getNavItemLink = ({
  item,
  section,
  portal,
}: {
  item: PortalsNav.Item;
  section: PortalSideNavigationSection;
  portal: BonusPortal;
}) => {
  switch (item.type) {
    case 'Dashboard':
      return buildDashboardShow({ id: item.dashboardId, portal, section });
    case 'Board':
    case 'Sign Off Board':
      return buildShowSignOffBoard({ portal, section, boardId: item.boardId });
    case 'Dashboards Index':
      return `/${portal.urlSlug}/${section.urlSlug}/dashboards`;
    case 'Report':
      return buildReportShow({ id: item.reportId, portal, section });
    case 'Reports Index':
      return `/${portal.urlSlug}/${section.urlSlug}/reports`;
    case 'Scorecard':
      return buildShowGlobalKpiList({
        scorecardId: item.scorecardId,
        portal,
        section,
      });
    case 'Bonus Home':
      return `/${portal.urlSlug}/${section.urlSlug}`;
    case 'Entity App':
      return buildEntityAppRedirect({
        portal,
        entityId: item.entityId,
        section,
      });
    case 'Driver Scoring Program Report':
      return buildDriverScoringProgramReportShow({ section, portal });
    case 'Scoring Program Config Overview':
      return buildScoringProgramConfigShow({ section, portal });
    default:
      return '';
  }
};

export default getNavItemLink;
