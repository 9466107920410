import { useCallback, useContext } from 'react';
import PERMISSIONS from '../permissionDefinitions';
import CurrentUserContext from 'contexts/CurrentUserContext';
import ImpersonatorContext from 'contexts/ImpersonatorContext';
import RolesContext from 'contexts/RolesContext';
import WallboardContext from 'contexts/WallboardContext';
import { PortalsContext } from '../contextProviders/SplashScreenProviders/UserAndAccountProviders/PortalsProvider';
import portalTypeCheckers from '../types/portalTypeCheckers';
import { SharedContext } from '../contextProviders/SplashScreenProviders/ContentDependantProviders/SharedProvider';
import portalNavItemTypeCheckers from '../types/portalNavItemTypeCheckers';
import _ from 'lodash';

// Note: This should be used after the ContentProvider in the SplashScreen
const useHasAccess = () => {
  const currentUser = useContext(CurrentUserContext);
  const { isWallboard } = useContext(WallboardContext);
  const { currentPermissions } = useContext(RolesContext);
  const { impersonatorId } = useContext(ImpersonatorContext);
  const { selectedPortal, isPortalsEnabled, availablePortals } =
    useContext(PortalsContext);
  const { sharedContent } = useContext(SharedContext);

  const isShared = useCallback(
    ({
      type,
      typeId,
    }: {
      type:
        | 'targetsApp'
        | 'entity'
        | 'dashboard'
        | 'report'
        | 'wallboard'
        | 'workspace'
        | 'goal'
        | 'scorecard';
      typeId: string;
    }) => {
      if (type === 'report') {
        return sharedContent.reports.some((r) => r.id === typeId);
      } else if (type === 'dashboard') {
        return sharedContent.dashboards.some((d) => d.id === typeId);
      } else if (type === 'entity') {
        return true;
      } else if (type === 'scorecard') {
        return sharedContent.scorecards.some((s) => s.id === typeId);
      } else if (type === 'workspace') {
        return sharedContent.workSpaces.some((w) => w.id === typeId);
      }
      return false;
    },
    [sharedContent],
  );

  const portalHasAccess = useCallback(
    ({
      type,
      typeId,
      portal,
    }: {
      type:
        | 'targetsApp'
        | 'entity'
        | 'dashboard'
        | 'report'
        | 'wallboard'
        | 'workspace'
        | 'goal'
        | 'scorecard';
      typeId: string;
      portal: Portal;
    }) => {
      if (portalTypeCheckers.isAdminPortal(portal)) {
        return true;
      } else if (
        portalTypeCheckers.isExecutivePortal(portal) ||
        portalTypeCheckers.isEngagementPortal(portal)
      ) {
        if (type === 'entity') {
          return true;
        }

        if (type === 'scorecard' && portal.scorecardIds.includes(typeId)) {
          return true;
        }

        if (type === 'report' && portal.reportIds.includes(typeId)) {
          return true;
        }

        if (type === 'dashboard' && portal.dashboardIds.includes(typeId)) {
          return true;
        }

        return false;
      } else if (portalTypeCheckers.isBonusPortal(portal)) {
        const reportIds = _.uniq(
          portal.sideNavigationSections.reduce((currentReportIds, section) => {
            const directReports = section.items
              .filter(portalNavItemTypeCheckers.isReport)
              .map((i) => i.reportId);

            const reportsInFolder = section.items
              .filter(portalNavItemTypeCheckers.isReportsIndex)
              .map((i) => i.reportIds)
              .reduce((a, b) => {
                return [...a, ...b];
              }, []);

            return [...currentReportIds, ...directReports, ...reportsInFolder];
          }, [] as string[]),
        );

        const dashboardIds = _.uniq(
          portal.sideNavigationSections.reduce(
            (currentDashboardIds, section) => {
              const directDashboards = section.items
                .filter(portalNavItemTypeCheckers.isDashboard)
                .map((i) => i.dashboardId);

              const dashboardsInFolder = section.items
                .filter(portalNavItemTypeCheckers.isDashboardsIndex)
                .map((i) => i.dashboardIds)
                .reduce((a, b) => {
                  return [...a, ...b];
                }, []);

              return [
                ...currentDashboardIds,
                ...directDashboards,
                ...dashboardsInFolder,
              ];
            },
            [] as string[],
          ),
        );

        const scorecardIds = _.uniq(
          portal.sideNavigationSections.reduce(
            (currentScorecardIds, section) => {
              const directScorecards = section.items
                .filter(portalNavItemTypeCheckers.isScorecard)
                .map((i) => i.scorecardId);

              return [...currentScorecardIds, ...directScorecards];
            },
            [] as string[],
          ),
        );

        if (type === 'entity') {
          return true;
        }

        if (type === 'scorecard' && scorecardIds.includes(typeId)) {
          return true;
        }

        if (type === 'report' && reportIds.includes(typeId)) {
          return true;
        }

        if (type === 'dashboard' && dashboardIds.includes(typeId)) {
          return true;
        }
      } else {
        return false;
      }
    },
    [],
  );

  const hasPortalAccess = useCallback(
    ({
      type,
      typeId,
    }: {
      type:
        | 'targetsApp'
        | 'entity'
        | 'dashboard'
        | 'report'
        | 'wallboard'
        | 'workspace'
        | 'goal'
        | 'scorecard';
      typeId: string;
    }) => {
      return availablePortals.some((portal) =>
        portalHasAccess({ portal, type, typeId }),
      );
    },
    [availablePortals, portalHasAccess],
  );

  const currentPortalHasAccess = useCallback(
    ({
      type,
      typeId,
    }: {
      type:
        | 'targetsApp'
        | 'entity'
        | 'dashboard'
        | 'report'
        | 'wallboard'
        | 'workspace'
        | 'goal'
        | 'scorecard';
      typeId: string;
    }) => {
      if (!selectedPortal) {
        return false;
      }

      return portalHasAccess({
        portal: selectedPortal,
        type,
        typeId,
      });
    },
    [portalHasAccess, selectedPortal],
  );

  const legacyHasAccess = useCallback(
    ({ access, resource }: { access: ResourceAccess; resource: Resource }) => {
      if (currentUser.isWallboardUser || isWallboard) {
        return true;
      }
      const currentUserId = impersonatorId || currentUser.id;

      if (access.type === 'Public') {
        return true;
      } else if (access.type === 'Admin Only') {
        return currentPermissions.includes(
          PERMISSIONS.CONTENT_ACCESS.VIEW_ALL_ADMIN_ONLY,
        );
      } else if (access.type === 'Private') {
        return (
          resource.createdBy === currentUserId ||
          currentPermissions.includes(
            PERMISSIONS.CONTENT_ACCESS.VIEW_ALL_PRIVATE,
          )
        );
      } else if (access.type === 'Users' && access.userIds) {
        return (
          access.userIds.includes(currentUserId) ||
          resource.createdBy === currentUserId ||
          currentPermissions.includes(PERMISSIONS.CONTENT_ACCESS.VIEW_ALL_USERS)
        );
      } else {
        return true;
      }
    },
    [impersonatorId, currentUser, isWallboard, currentPermissions],
  );

  const hasAccess = useCallback(
    ({
      access,
      resource,
      type,
      typeId,
    }: {
      access: ResourceAccess;
      resource: Resource | DashboardType | ReportType;
      type:
        | 'targetsApp'
        | 'entity'
        | 'dashboard'
        | 'report'
        | 'wallboard'
        | 'workspace'
        | 'goal'
        | 'scorecard';
      typeId: string;
    }) => {
      if (!isPortalsEnabled) {
        return legacyHasAccess({ access, resource });
      }

      if (selectedPortal) {
        if (type === 'workspace') {
          return (
            currentPermissions.includes(
              PERMISSIONS.CONTENT_ACCESS.VIEW_ADMIN_PORTAL,
            ) ||
            currentPortalHasAccess({ type, typeId }) ||
            isShared({ type, typeId })
          );
        }
        return currentPortalHasAccess({ type, typeId });
      }

      return hasPortalAccess({ type, typeId }) || isShared({ type, typeId });
    },
    [
      isPortalsEnabled,
      selectedPortal,
      hasPortalAccess,
      isShared,
      legacyHasAccess,
      currentPortalHasAccess,
      currentPermissions,
    ],
  );

  return hasAccess;
};

export default useHasAccess;
