import { useCallback, useContext } from 'react';
import RolesContext from '../../../../../contexts/RolesContext';
import CurrentUserContext from '../../../../../contexts/CurrentUserContext';
import ImpersonatorContext from '../../../../../contexts/ImpersonatorContext';

const useGetHasBonusPortalAccess = () => {
  const { currentPermissions } = useContext(RolesContext);
  const currentUser = useContext(CurrentUserContext);
  const { impersonatorId } = useContext(ImpersonatorContext);

  const getHasBonusPortalAccess = useCallback(
    (app: BonusPortal) => {
      const currentUserId = impersonatorId ? impersonatorId : currentUser.id;
      const isManager = app.bonusPeriodSignOffUserIds.some(
        (mid) => mid === currentUserId,
      );
      const isAdmin = currentPermissions.some(
        (p) => p === 'fleetops.permissions.content_access_view_all_admin_only',
      );
      const isReader = app.readOnlyUserIds.some((mid) => mid === currentUserId);
      const isBoardOwner = app.sideNavigationSections.some((section) =>
        section.items.some(
          (c) =>
            c.type === 'Sign Off Board' &&
            c.signOff.ownerIds.includes(currentUserId),
        ),
      );

      const hasAccess = isManager || isAdmin || isReader || isBoardOwner;
      return hasAccess;
    },
    [currentPermissions, currentUser.id, impersonatorId],
  );

  return getHasBonusPortalAccess;
};

export default useGetHasBonusPortalAccess;
