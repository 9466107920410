import React, { useMemo, useState } from 'react';
import Dropdown from 'components/Inputs/Dropdown';
import Form from 'kingpin/forms/Form';
import FormHeader from 'kingpin/forms/FormHeader';
import FormContent from 'kingpin/forms/FormContent';
import FormInput from 'kingpin/forms/FormInput';
import Alert from 'kingpin/Alert';
import portalNavItemTypeCheckers from 'types/portalNavItemTypeCheckers';

import NavReportForm from './NavReportForm';
import NavDashboardForm from './NavDashboardForm';
import NavScorecardForm from './NavScorecardForm';
import NavSignOffBoardForm from './NavSignOffBoardForm';
import NavBoardForm from './NavBoardForm';
import NavEntityAppForm from './NavEntityAppForm';
import NavReportsIndexForm from './NavReportsIndexForm';
import NavDashboardsIndexForm from './NavDashboardsIndexForm';
import NavBonusHomeForm from './NavBonusHomeForm';
import NavBonusPickerForm from './NavBonusPickerForm';
import NavDriverScoringProgramReportForm from './NavDriverScoringProgramReportForm';
import NavScoringProgramConfigOverviewForm from './NavScoringProgramConfigOverviewForm';

const NavItemForm = ({
  item,
  onItemSaved,
  close,
}: {
  item?: PortalsNav.Item;
  onItemSaved: (item: PortalsNav.Item) => void;
  close: () => void;
}) => {
  const [type, setType] = useState<PortalsNav.NavItemType | undefined>(
    item ? item.type : undefined,
  );
  const typeOptions = useMemo((): DropdownOption[] => {
    return [
      {
        label: 'Report',
        isSelected: type === 'Report',
        onSelected: () => {
          setType('Report');
        },
      },
      {
        label: 'Dashboard',
        isSelected: type === 'Dashboard',
        onSelected: () => {
          setType('Dashboard');
        },
      },
      {
        label: 'Scorecard',
        isSelected: type === 'Scorecard',
        onSelected: () => {
          setType('Scorecard');
        },
      },
      {
        label: 'Entity App',
        isSelected: type === 'Entity App',
        onSelected: () => {
          setType('Entity App');
        },
      },
      {
        label: 'Reports Index',
        isSelected: type === 'Reports Index',
        onSelected: () => {
          setType('Reports Index');
        },
      },
      {
        label: 'Dashboards Index',
        isSelected: type === 'Dashboards Index',
        onSelected: () => {
          setType('Dashboards Index');
        },
      },
      {
        label: 'Board',
        isSelected: type === 'Board',
        onSelected: () => {
          setType('Board');
        },
      },
      {
        label: 'Sign Off Board',
        isSelected: type === 'Sign Off Board',
        onSelected: () => {
          setType('Sign Off Board');
        },
      },
      {
        label: 'Bonus Home',
        isSelected: type === 'Bonus Home',
        onSelected: () => {
          setType('Bonus Home');
        },
      },
      {
        label: 'Bonus Period Picker',
        isSelected: type === 'Bonus Period Picker',
        onSelected: () => {
          setType('Bonus Period Picker');
        },
      },
      {
        label: 'Driver Scoring Program Report',
        isSelected: type === 'Driver Scoring Program Report',
        onSelected: () => {
          setType('Driver Scoring Program Report');
        },
      },
      {
        label: 'Scoring Program Config Overview',
        isSelected: type === 'Scoring Program Config Overview',
        onSelected: () => {
          setType('Scoring Program Config Overview');
        },
      },
    ];
  }, [type]);

  const FormBody = useMemo(() => {
    if (!type) {
      return null;
    }

    if (type === 'Report') {
      return (
        <NavReportForm
          close={close}
          onSave={onItemSaved}
          item={
            item && portalNavItemTypeCheckers.isReport(item) ? item : undefined
          }
        />
      );
    }

    if (type === 'Dashboard') {
      return (
        <NavDashboardForm
          item={
            item && portalNavItemTypeCheckers.isDashboard(item)
              ? item
              : undefined
          }
          onSave={onItemSaved}
          close={close}
        />
      );
    }

    if (type === 'Scorecard') {
      return (
        <NavScorecardForm
          item={
            item && portalNavItemTypeCheckers.isScorecard(item)
              ? item
              : undefined
          }
          onSave={onItemSaved}
          close={close}
        />
      );
    }

    if (type === 'Board') {
      return (
        <NavBoardForm
          item={
            item && portalNavItemTypeCheckers.isBoard(item) ? item : undefined
          }
          onSave={onItemSaved}
          close={close}
        />
      );
    }

    if (type === 'Sign Off Board') {
      return (
        <NavSignOffBoardForm
          item={
            item && portalNavItemTypeCheckers.isSignOffBoard(item)
              ? item
              : undefined
          }
          onSave={onItemSaved}
          close={close}
        />
      );
    }

    if (type === 'Entity App') {
      return (
        <NavEntityAppForm
          item={
            item && portalNavItemTypeCheckers.isEntityApp(item)
              ? item
              : undefined
          }
          onSave={onItemSaved}
          close={close}
        />
      );
    }

    if (type === 'Reports Index') {
      return (
        <NavReportsIndexForm
          item={
            item && portalNavItemTypeCheckers.isReportsIndex(item)
              ? item
              : undefined
          }
          onSave={onItemSaved}
          close={close}
        />
      );
    }

    if (type === 'Dashboards Index') {
      return (
        <NavDashboardsIndexForm
          item={
            item && portalNavItemTypeCheckers.isDashboardsIndex(item)
              ? item
              : undefined
          }
          onSave={onItemSaved}
          close={close}
        />
      );
    }

    if (type === 'Bonus Home') {
      return (
        <NavBonusHomeForm
          item={
            item && portalNavItemTypeCheckers.isBonusHome(item)
              ? item
              : undefined
          }
          onSave={onItemSaved}
          close={close}
        />
      );
    }

    if (type === 'Bonus Period Picker') {
      return (
        <NavBonusPickerForm
          item={
            item && portalNavItemTypeCheckers.isBonusPicker(item)
              ? item
              : undefined
          }
          onSave={onItemSaved}
          close={close}
        />
      );
    }

    if (type === 'Driver Scoring Program Report') {
      return (
        <NavDriverScoringProgramReportForm
          item={
            item && portalNavItemTypeCheckers.isDriverScoringProgramReport(item)
              ? item
              : undefined
          }
          onSave={onItemSaved}
          close={close}
        />
      );
    }

    if (type === 'Scoring Program Config Overview') {
      return (
        <NavScoringProgramConfigOverviewForm
          item={
            item &&
            portalNavItemTypeCheckers.isScoringProgramConfigOverview(item)
              ? item
              : undefined
          }
          onSave={onItemSaved}
          close={close}
        />
      );
    }

    return (
      <Alert
        type={'Warning'}
        title={`Unexpected Type: ${type}`}
        body={'Please try something different'}
      />
    );
  }, [close, item, onItemSaved, type]);

  return (
    <Form>
      <FormHeader
        title={item ? 'Update Nav Item' : 'Add Nav Item'}
        onClose={close}
      />
      <FormContent>
        <FormInput label={'Type'}>
          <Dropdown options={typeOptions} />
        </FormInput>
        {FormBody}
      </FormContent>
    </Form>
  );
};

export default NavItemForm;
