const isReport = (item: PortalsNav.Item): item is PortalsNav.Report =>
  item.type === 'Report';

const isDashboard = (item: PortalsNav.Item): item is PortalsNav.Dashboard =>
  item.type === 'Dashboard';

const isScorecard = (item: PortalsNav.Item): item is PortalsNav.Scorecard =>
  item.type === 'Scorecard';

const isEntityApp = (item: PortalsNav.Item): item is PortalsNav.EntityApp =>
  item.type === 'Entity App';

const isReportsIndex = (
  item: PortalsNav.Item,
): item is PortalsNav.ReportsIndex => item.type === 'Reports Index';

const isDashboardsIndex = (
  item: PortalsNav.Item,
): item is PortalsNav.DashboardsIndex => item.type === 'Dashboards Index';

const isSignOffBoard = (
  item: PortalsNav.Item,
): item is PortalsNav.SignOffBoard => item.type === 'Sign Off Board';

const isBoard = (item: PortalsNav.Item): item is PortalsNav.Board =>
  item.type === 'Board';

const isBonusHome = (item: PortalsNav.Item): item is PortalsNav.BonusHome =>
  item.type === 'Bonus Home';

const isBonusPicker = (
  item: PortalsNav.Item,
): item is PortalsNav.BonusPeriodPicker => item.type === 'Bonus Period Picker';

const isDriverScoringProgramReport = (
  item: PortalsNav.Item,
): item is PortalsNav.DriverScoringProgramReport =>
  item.type === 'Driver Scoring Program Report';

const isScoringProgramConfigOverview = (
  item: PortalsNav.Item,
): item is PortalsNav.ScoringProgramConfigOverview =>
  item.type === 'Scoring Program Config Overview';

const portalNavItemTypeCheckers = {
  isReport,
  isDashboard,
  isScorecard,
  isEntityApp,
  isReportsIndex,
  isDashboardsIndex,
  isSignOffBoard,
  isBoard,
  isBonusHome,
  isBonusPicker,
  isDriverScoringProgramReport,
  isScoringProgramConfigOverview,
};

export default portalNavItemTypeCheckers;
