import styled from 'styled-components';
import { useContext, useMemo } from 'react';
import { AppsContext } from '../../../../../contextProviders/SplashScreenProviders/UserAndAccountProviders/AppsProvider';
import AccountContext from '../../../../../contexts/AccountContext';
import AccountPickerContext from '../../../../../contexts/AccountPickerContext';
import Row from '../../../../../components/Common/Row';
import Icon from '../../../../atoms/Icon';
import AppIconDiv from './AppIconDiv';

const SwitcherButtonDiv = styled.div<{ isOpen: boolean }>`
  cursor: pointer;
  height: 41px;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-radius: 8px;
  padding: 8px;

  background-color: ${(props) => (props.isOpen ? '#EEEFF1' : '#FBFBFB')};

  &:hover {
    background-color: #eeeff1;
  }
`;

const AppTitle = styled.h2`
  margin: 0px;
  font-family: Inter;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #2c2c2c;
`;

const CarrierName = styled.h3`
  margin: 0px;
  font-family: Inter;
  font-weight: 500;
  font-size: 10px;
  line-height: 12px;
  color: #939393;
`;

const SwitcherButton = ({
  onClick,
  isOpen,
}: {
  onClick: () => void;
  isOpen: boolean;
}) => {
  const { selectedApp } = useContext(AppsContext);
  const { carrierName } = useContext(AccountContext);
  const { accounts } = useContext(AccountPickerContext);

  const appIcon = useMemo((): Kingpin.Icon => {
    if (selectedApp) {
      return selectedApp.icon;
    }
    return 'apps-driver-ax';
  }, [selectedApp]);

  const appLabel = useMemo(() => {
    if (selectedApp) {
      return selectedApp.label;
    }

    return 'Home';
  }, [selectedApp]);

  return (
    <SwitcherButtonDiv onClick={onClick} isOpen={isOpen}>
      <Row style={{ gap: 8 }} centerAlign>
        <AppIconDiv>
          <Icon icon={appIcon} width={26} height={26} />
        </AppIconDiv>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
          }}
        >
          <AppTitle>{appLabel}</AppTitle>
          {accounts.length > 1 && <CarrierName>{carrierName}</CarrierName>}
        </div>
      </Row>
      <div>
        <Icon icon={'chevron-up-down'} />
      </div>
    </SwitcherButtonDiv>
  );
};

export default SwitcherButton;
