import React, { useCallback, useContext, useEffect, useState } from 'react';

import AccountContext from '../contexts/AccountContext';
import isGeneralBoard from '../isBoardWithSlideOut';
import getDateRangePeriods from '../api/getDateRangePeriods';
import relativeDateRangeToDateRange from '../relativeDateRangeToDateRange';
import PeriodsContext from '../contexts/PeriodsContext';
import GqlClientContext from '../contexts/GqlClientContext';
import DatasetDefinitionsContext from '../contexts/DatasetDefinitionsContext';
import isPerformanceBoard from '../isPerformanceBoard';
import {
  LAST_30_DAYS_INCLUDING_TODAY,
  LAST_365_DAYS_INCLUDING_TODAY,
} from '../components/DateInput/constants';
import moment from 'moment';
import isoDateToAmerican from '../isoDateToAmerican';
import { PortalsContext } from './SplashScreenProviders/UserAndAccountProviders/PortalsProvider';
import portalTypeCheckers from '../types/portalTypeCheckers';

export const useGetCadence = (dataType: string) => {
  const { getPerformanceConfig } = useContext(DatasetDefinitionsContext);

  const getCadence = useCallback(() => {
    if (
      dataType === 'driverDays' ||
      dataType === 'truckDays' ||
      dataType === 'trailerDays'
    ) {
      return {
        cadence: 'day' as 'day',
        cadenceInterval: 1,
      };
    }

    if (
      dataType === 'driverWeeks' ||
      dataType === 'truckWeeks' ||
      dataType === 'trailerWeeks'
    ) {
      return {
        cadence: 'week' as 'week',
        cadenceInterval: 1,
      };
    }

    if (dataType === 'commission') {
      return {
        cadence: 'month' as 'month',
        cadenceInterval: 1,
      };
    }

    const perfConfig = getPerformanceConfig(dataType);
    if (!perfConfig) {
      return {
        cadence: 'week' as 'week',
        cadenceInterval: 1,
      };
    }

    return {
      cadence: perfConfig.interval,
      cadenceInterval: perfConfig.intervalLength,
    };
  }, [dataType, getPerformanceConfig]);

  return getCadence;
};

const usePeriods = (board: GeneralBoard | PerformanceBoardTypes.Board) => {
  const { weekStartsOn, isDemoAccount, demoAccountNow, isDemo6 } =
    useContext(AccountContext);
  const { client } = useContext(GqlClientContext);
  const { getPerformanceConfig } = useContext(DatasetDefinitionsContext);
  const [periods, setPeriods] = useState<Period[]>([]);
  const getCadence = useGetCadence(board.dataType);

  useEffect(() => {
    const { cadence, cadenceInterval } = getCadence();
    let isActive = true;
    const performanceConfig = getPerformanceConfig(board.dataType);
    const startOfWeek =
      performanceConfig && performanceConfig.startOfWeek
        ? performanceConfig.startOfWeek
        : weekStartsOn;

    const dateScope: DateRangeInput = (() => {
      const isTrimbleDemo =
        isDemo6 && board.dataType === 'performanceDriver13Weeks';
      if (isDemoAccount && demoAccountNow && !isTrimbleDemo) {
        const startDate = moment(demoAccountNow)
          .subtract({ day: cadence === 'day' ? 30 : 365 })
          .format('YYYY-MM-DD');
        return {
          startDate,
          endDate: demoAccountNow,
        };
      }

      const relativeDateRange: RelativeDateRange = (() => {
        if (isPerformanceBoard(board)) {
          return cadence === 'day'
            ? LAST_30_DAYS_INCLUDING_TODAY
            : LAST_365_DAYS_INCLUDING_TODAY;
        }
        return board.relativeDateRange;
      })();

      return relativeDateRangeToDateRange({
        relativeDateRange,
        startOfWeek,
      });
    })();

    getDateRangePeriods({
      dateScope,
      dataType: board.dataType,
      client,
      cadence,
      cadenceInterval,
    }).then((newPeriods) => {
      if (!isActive) {
        return;
      }
      const sortedPeriods = newPeriods.sort((a, b) => {
        if (a.startDate < b.startDate) {
          return 1;
        } else if (a.startDate === b.startDate) {
          return 0;
        } else {
          return -1;
        }
      });

      setPeriods(sortedPeriods);
    });
    return () => {
      isActive = false;
    };
  }, [
    board,
    client,
    demoAccountNow,
    getCadence,
    getPerformanceConfig,
    isDemo6,
    isDemoAccount,
    weekStartsOn,
  ]);

  return periods;
};

const UpcomingEventsBoardPeriodsProvider = ({
  children,
  board,
  popupDateRange,
}: {
  children: JSX.Element | JSX.Element[];
  board: GeneralBoard;
  popupDateRange?: DateRangeInput;
}) => {
  const [selectedPeriod, setSelectedPeriod] = useState<Period | undefined>();
  useEffect(() => {
    if (
      popupDateRange &&
      popupDateRange.startDate &&
      popupDateRange.endDate &&
      !selectedPeriod
    ) {
      const eventsPeriod: Period = {
        startDate: popupDateRange.startDate,
        endDate: popupDateRange.endDate,
        label: `${isoDateToAmerican(popupDateRange.startDate)} - ${isoDateToAmerican(popupDateRange.endDate)}`,
      };
      setSelectedPeriod(eventsPeriod);
      return;
    }
  }, [selectedPeriod, board.dataType, popupDateRange]);

  return (
    <PeriodsContext.Provider
      value={{ periods: window.emptyArray, selectedPeriod, setSelectedPeriod }}
    >
      {children}
    </PeriodsContext.Provider>
  );
};

const BoardPeriodsProvider = ({
  children,
  board,
  initialDateRange,
}: {
  children: JSX.Element | JSX.Element[];
  board: GeneralBoard | PerformanceBoardTypes.Board;
  initialDateRange?: DateRangeInput;
}) => {
  const periods = usePeriods(board);
  const [selectedPeriod, setSelectedPeriod] = useState<Period | undefined>();
  useEffect(() => {
    if (periods.length > 0 && !selectedPeriod) {
      if (initialDateRange) {
        const matchingPeriod = periods.find(
          (p) =>
            p.startDate === initialDateRange.startDate &&
            p.endDate === initialDateRange.endDate,
        );
        if (matchingPeriod) {
          setSelectedPeriod(matchingPeriod);
          return;
        }
      }

      setSelectedPeriod(periods[0]);
    }
  }, [periods, initialDateRange, selectedPeriod, board.dataType]);

  return (
    <PeriodsContext.Provider
      value={{ periods, selectedPeriod, setSelectedPeriod }}
    >
      {children}
    </PeriodsContext.Provider>
  );
};

/**
 * Please refer to {@link BonusPortalProvider} for period logic in the context of
 * a bonus portal
 *
 * We still need these board level periods for legacy solutions
 */
const Gate = ({
  children,
  board,
  initialDateRange,
}: {
  children: JSX.Element | JSX.Element[];
  board: GeneralBoard | DriverPerformanceBoard | PerformanceBoardTypes.Board;
  initialDateRange?: DateRangeInput;
}) => {
  const { selectedPortal } = useContext(PortalsContext);
  if (portalTypeCheckers.isBonusPortal(selectedPortal)) {
    return <>{children}</>;
  }

  if (
    isGeneralBoard(board) &&
    board.dataType === 'upcomingEvents' &&
    initialDateRange
  ) {
    return (
      <UpcomingEventsBoardPeriodsProvider
        board={board}
        popupDateRange={initialDateRange}
      >
        {children}
      </UpcomingEventsBoardPeriodsProvider>
    );
  } else if (isGeneralBoard(board) || isPerformanceBoard(board)) {
    return (
      <BoardPeriodsProvider board={board} initialDateRange={initialDateRange}>
        {children}
      </BoardPeriodsProvider>
    );
  } else {
    return <>{children}</>;
  }
};

export default Gate;
