import React, { useContext } from 'react';

import NavSideBarContext from 'contexts/NavSideBarContext';
import Row from 'components/Common/Row';
import Colors2 from 'theme/Colors2';
import styled from 'styled-components';

import MainNavBottom from '../../MainNavBottom';
import NAVIGATION from '../../constants';
import FavoritesSection from '../../FavoritesSection';
import PortalNavSection from '../PortalNavSection';
import AppsNavTop from './AppsNavTop';
import NavSection from '../../NavSection';
import FeatureGate, { FEATURE_GATES } from '../../../../components/FeatureGate';
import GlobalSearch from '../../../../components/GlobalSearch';
import MainNavFavouritesSection from '../../MainNavFavouritesSection';
import NavItem from '../../NavItem';
import AppRoutes from '../../../../navigation/appRoutes';
import { AppsContext } from '../../../../contextProviders/SplashScreenProviders/UserAndAccountProviders/AppsProvider';
import PortalPicker from '../../PortalPicker';

export const MainNavDiv = styled.div<{ isCollapsed: boolean }>`
  width: ${(props) =>
    props.isCollapsed
      ? NAVIGATION.COLLAPSED_NAV_WIDTH
      : NAVIGATION.OPENED_NAV_WIDTH}px;
  min-width: ${(props) =>
    props.isCollapsed
      ? NAVIGATION.COLLAPSED_NAV_WIDTH
      : NAVIGATION.OPENED_NAV_WIDTH}px;
  height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: #fbfbfb;
  justify-content: space-between;
  border-right: 1px solid ${Colors2.Border};
`;

export const MainNavPrimaryContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

/**
 *
 * This is our latest product offering, powered by {@link AppsProvider}
 *
 */
const AppsMainNav = () => {
  const { isOpen } = useContext(NavSideBarContext);
  const { selectedApp } = useContext(AppsContext);

  return (
    <Row>
      <MainNavDiv isCollapsed={!isOpen}>
        <MainNavPrimaryContent>
          <AppsNavTop />
          {selectedApp && selectedApp.type === 'Analytics' && (
            <>
              <NavSection>
                <FeatureGate featureName={FEATURE_GATES.ENTITY_DETAILS}>
                  <GlobalSearch />
                </FeatureGate>
                <MainNavFavouritesSection />
                <NavItem
                  to={AppRoutes.loggedIn.workspaces}
                  label={'Workspaces'}
                  tooltip={'Workspaces'}
                  icon={'workspace'}
                  onClickEvent={'Navigation Sidebar - Clicked Workspaces'}
                />
                <NavItem
                  to={AppRoutes.loggedIn.shared}
                  label={'Shared'}
                  tooltip={'Shared'}
                  icon={'shared'}
                  onClickEvent={'Navigation Sidebar - Clicked Shared'}
                />
              </NavSection>
              <NavSection hideBorderBottom>
                <PortalPicker isExecOnly />
              </NavSection>
            </>
          )}

          <PortalNavSection />
        </MainNavPrimaryContent>
        <MainNavBottom />
      </MainNavDiv>
      <FavoritesSection />
    </Row>
  );
};

export default AppsMainNav;
