import React, { lazy, useEffect, useMemo } from 'react';
import { Route, Routes, useNavigate, useParams } from 'react-router-dom';
import appRoutes from './appRoutes';
import BonusPortalPeriodFilterContext from './BonusPortalPeriodFilterContext';
import NavSectionContext from './NavSectionContext';
import getNavItemLink from '../kingpin/navigation/MainNav/PortalNavSection/getNavItemLink';
import Loading from '../components/Loading';
import ErrorBoundary from '../components/Common/ErrorBoundary';
import BonusPeriodsContext from 'contexts/BonusPeriodsContext';
import PeriodsContext from 'contexts/PeriodsContext';

const BonusPortalHome = lazy(() => import('../screens/BonusPortalHome'));
const SharedIndex = lazy(() => import('../screens/SharedIndex'));
const WorkSpacesIndex = lazy(() => import('../screens/WorkSpacesIndex'));
const WorkSpacesShow = lazy(() => import('../screens/WorkSpacesShow'));
const DashboardsIndex = lazy(() => import('../screens/Dashboards'));
const DashboardShow = lazy(() => import('../screens/DashboardShow'));
const BoardShow = lazy(() => import('../screens/BoardShow'));
const ReportsIndex = lazy(() => import('../screens/Reports'));
const ReportShow = lazy(() => import('../screens/ReportShow'));
const ScorecardsIndex = lazy(() => import('../screens/ScorecardsIndex'));
const ScorecardShow = lazy(() => import('../screens/ScorecardShow'));
const EntityDetailsShow = lazy(() => import('../screens/EntityDetailsShow'));
const EntityDetailsShowRedirect = lazy(
  () => import('../screens/EntityDetailsShowRedirect'),
);
const DriverScoringProgramReport = lazy(
  () => import('../screens/DriverScoringProgramReport'),
);
const ScoringProgramConfigOverview = lazy(
  () => import('../screens/ScoringProgramConfigOverview'),
);

const VoidSelectedPeriodProvider = ({
  children,
}: {
  children: JSX.Element | JSX.Element[];
}) => {
  return (
    <BonusPeriodsContext.Provider
      value={{
        bonusPeriods: window.emptyArray,
        setSelectedBonusPeriod: window.tokenFunction,
      }}
    >
      <PeriodsContext.Provider
        value={{
          setSelectedPeriod: window.tokenFunction,
          periods: window.emptyArray,
        }}
      >
        {children}
      </PeriodsContext.Provider>
    </BonusPeriodsContext.Provider>
  );
};

const SectionPeriodFilterProvider = ({
  children,
  isPeriodFiltered,
}: {
  children: JSX.Element | JSX.Element[];
  isPeriodFiltered: boolean;
}) => {
  if (isPeriodFiltered) {
    return <>{children}</>;
  }

  return <VoidSelectedPeriodProvider>{children}</VoidSelectedPeriodProvider>;
};

const SectionGate = ({
  section,
  children,
}: {
  section: PortalSideNavigationSection;
  children: JSX.Element | JSX.Element[];
}) => {
  return (
    <NavSectionContext.Provider value={{ navSection: section }}>
      <BonusPortalPeriodFilterContext.Provider
        value={{ isPeriodFilterEnabled: section.isPeriodFiltered }}
      >
        <SectionPeriodFilterProvider
          isPeriodFiltered={section.isPeriodFiltered}
        >
          {children}
        </SectionPeriodFilterProvider>
      </BonusPortalPeriodFilterContext.Provider>
    </NavSectionContext.Provider>
  );
};

const SectionSwitch = ({ portal }: { portal: BonusPortal }) => {
  const { section: sectionSlug } = useParams<{ section: string }>();
  const section = useMemo(() => {
    return portal.sideNavigationSections.find((s) => s.urlSlug === sectionSlug);
  }, [portal.sideNavigationSections, sectionSlug]);

  if (!section) {
    return null;
  }

  return (
    <SectionGate section={section}>
      <Routes>
        <Route path={''} element={<BonusPortalHome />} />
        <Route
          path={appRoutes.loggedIn.dashboards}
          element={<DashboardsIndex />}
        />
        <Route
          path={appRoutes.loggedIn.showDashboard}
          element={<DashboardShow />}
        />
        <Route path={appRoutes.loggedIn.showBoard} element={<BoardShow />} />
        <Route path={appRoutes.loggedIn.reports} element={<ReportsIndex />} />
        <Route path={appRoutes.loggedIn.showReport} element={<ReportShow />} />
        <Route
          path={appRoutes.loggedIn.kpiLists}
          element={<ScorecardsIndex />}
        />
        <Route
          path={appRoutes.loggedIn.showGlobalKpiList}
          element={<ScorecardShow />}
        />
        <Route
          path={appRoutes.loggedIn.entityAppRedirect}
          element={<EntityDetailsShowRedirect />}
        />
        <Route
          path={appRoutes.loggedIn.showEntityApp}
          element={<EntityDetailsShow />}
        />
        <Route
          path={appRoutes.loggedIn.showEntityAppContentTab}
          element={<EntityDetailsShow />}
        />
        <Route
          path={appRoutes.loggedIn.driverScoringProgramReport}
          element={
            <ErrorBoundary>
              <DriverScoringProgramReport />
            </ErrorBoundary>
          }
        />
        <Route
          path={appRoutes.loggedIn.scoringProgramConfig}
          element={
            <ErrorBoundary>
              <ScoringProgramConfigOverview />
            </ErrorBoundary>
          }
        />
      </Routes>
    </SectionGate>
  );
};

const useDefaultBonusPortalScreenPath = (portal: BonusPortal) => {
  return useMemo(() => {
    const defaultSection = portal.sideNavigationSections.find(
      (s) => s.key === portal.defaultScreen.sectionKey,
    );
    if (!defaultSection) {
      return undefined;
    }

    const defaultItem = defaultSection.items.find(
      (i) => i.key === portal.defaultScreen.itemKey,
    );
    if (!defaultItem) {
      return undefined;
    }

    return getNavItemLink({
      portal,
      item: defaultItem,
      section: defaultSection,
    });
  }, [portal]);
};

const RedirectToDefaultScreen = ({ portal }: { portal: BonusPortal }) => {
  const navigate = useNavigate();
  const defaultPath = useDefaultBonusPortalScreenPath(portal);

  useEffect(() => {
    if (!defaultPath) {
      return;
    }

    navigate(defaultPath);
  }, [defaultPath, navigate]);

  return <Loading />;
};

const BonusPortalSwitch = ({ portal }: { portal: BonusPortal }) => {
  return (
    <>
      <Routes>
        <Route
          path={''}
          element={<RedirectToDefaultScreen portal={portal} />}
        />
        <Route path={appRoutes.loggedIn.shared} element={<SharedIndex />} />
        <Route
          path={appRoutes.loggedIn.workspaces}
          element={<WorkSpacesIndex />}
        />
        <Route
          path={appRoutes.loggedIn.showWorkSpace}
          element={
            <SectionPeriodFilterProvider isPeriodFiltered={false}>
              <WorkSpacesShow />
            </SectionPeriodFilterProvider>
          }
        />
        <Route
          path={appRoutes.loggedIn.showWorkSpaceTab}
          element={
            <SectionPeriodFilterProvider isPeriodFiltered={false}>
              <WorkSpacesShow />
            </SectionPeriodFilterProvider>
          }
        />
        <Route
          path={appRoutes.loggedIn.entityAppRedirect}
          element={<EntityDetailsShowRedirect />}
        />
        <Route
          path={appRoutes.loggedIn.showEntityApp}
          element={
            <SectionPeriodFilterProvider isPeriodFiltered={false}>
              <EntityDetailsShow />
            </SectionPeriodFilterProvider>
          }
        />
        <Route
          path={appRoutes.loggedIn.showEntityAppContentTab}
          element={
            <SectionPeriodFilterProvider isPeriodFiltered={false}>
              <EntityDetailsShow />
            </SectionPeriodFilterProvider>
          }
        />
        <Route
          path={'/:section/*'}
          element={<SectionSwitch portal={portal} />}
        />
      </Routes>
    </>
  );
};

export default BonusPortalSwitch;
