import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import ColorDot from '../ColorDot';
import getIdentifier from '../../../../getIdentifier';
import getTimeStamp from '../../../../getTimeStamp';
import CurrentUserContext from '../../../../contexts/CurrentUserContext';
import useUrlSlug from './useUrlSlug';
import portalTypeCheckers from '../../../../types/portalTypeCheckers';

const deriveUrlSlug = (name: string) => {
  return name.replace(/[^a-zA-Z]/g, '').toLowerCase();
};

const useFormState = (portal: EditablePortal | undefined) => {
  const { id: currentUserId } = useContext(CurrentUserContext);
  const [id] = useState<string>(portal ? portal.id : getIdentifier());
  const [portalType, setPortalType] = useState<
    'ExecutivePortal' | 'Engagement Portal' | 'Bonus Portal'
  >(portal ? portal.type : 'ExecutivePortal');

  const portalTypeOptions = useMemo(() => {
    return [
      {
        label: 'Executive Portal',
        isSelected: portalType === 'ExecutivePortal',
        onSelected: () => {
          setPortalType('ExecutivePortal');
        },
      },
      {
        label: 'Engagement Portal',
        isSelected: portalType === 'Engagement Portal',
        onSelected: () => {
          setPortalType('Engagement Portal');
        },
      },
      {
        label: 'Bonus Portal',
        isSelected: portalType === 'Bonus Portal',
        onSelected: () => {
          setPortalType('Bonus Portal');
        },
      },
    ];
  }, [portalType]);

  const { urlSlug, setUrlSlug, onUrlSlugChanged, isUrlSlugValid } =
    useUrlSlug(portal);
  const [name, setName] = useState<string>(portal ? portal.name : '');
  const onNameChanged = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const newName = event.target.value;
      setName((currentName) => {
        if (!portal) {
          setUrlSlug((currentSlug) => {
            const currentDerivedSlug = deriveUrlSlug(currentName);
            if (currentDerivedSlug === currentSlug) {
              // Update the slug if it is in sync
              return deriveUrlSlug(newName);
            }

            // Keep the current slug as it has been given an explicit value
            return currentSlug;
          });
        }

        // Set the new name
        return newName;
      });
    },
    [portal, setUrlSlug],
  );

  const [color, setColor] = useState<PortalColor>(
    portal ? portal.color : 'blue',
  );
  const getColorOptions = useCallback(() => {
    const colors: PortalColor[] = [
      'blue',
      'green',
      'yellow',
      'orange',
      'red',
      'magenta',
      'navy',
      'grey',
      'lightgrey',
    ];
    return colors.map((c) => ({
      isSelected: color === c,
      onSelected: () => {
        setColor(c);
      },
      key: c,
      label: '',
      renderLabel: () => <ColorDot color={c} key={c} width={20} height={20} />,
    }));
  }, [color]);
  const [colorOptions, setColorOptions] = useState<DropdownOption[]>(() =>
    getColorOptions(),
  );
  useEffect(() => {
    setColorOptions(getColorOptions());
  }, [getColorOptions]);

  // Engagement Portal State
  const [targetsLink, setTargetsLink] = useState<EngagementPortalTargetLink>(
    portalTypeCheckers.isEngagementPortal(portal)
      ? portal.targetsLink
      : {
          isEnabled: false,
        },
  );
  const [entityLinks, setEntityLinks] = useState<EngagementPortalEntityLink[]>(
    portalTypeCheckers.isEngagementPortal(portal) ? portal.entityLinks : [],
  );

  // Bonus Portal State
  const [bonusProgramId, setBonusProgramId] = useState<string | undefined>(
    portalTypeCheckers.isBonusPortal(portal)
      ? portal.bonusProgramId
      : undefined,
  );
  const [sideNavigationSections, setSideNavigationSections] = useState<
    PortalSideNavigationSection[]
  >(
    portalTypeCheckers.isBonusPortal(portal)
      ? portal.sideNavigationSections
      : [],
  );
  const [defaultScreen, setDefaultScreen] = useState<
    | {
        sectionKey: string;
        itemKey: string;
      }
    | undefined
  >(
    portalTypeCheckers.isBonusPortal(portal) ? portal.defaultScreen : undefined,
  );

  const [draftPortal, setDraftPortal] = useState<Portal | undefined>(portal);
  useEffect(() => {
    if (name === '' || !isUrlSlugValid) {
      setDraftPortal(undefined);
      return;
    }

    if (portalType === 'ExecutivePortal') {
      const newPortal: ExecutivePortal = {
        id,
        type: 'ExecutivePortal',
        name,
        urlSlug,
        color,
        managerIds: [],
        dashboardIds: [],
        reportIds: [],
        scorecardIds: [],
        createdOn: portal ? portal.createdOn : getTimeStamp(),
        updatedOn: getTimeStamp(),
        createdBy: portal ? portal.createdBy : currentUserId,
        updatedBy: currentUserId,
      };
      setDraftPortal(newPortal);
      return;
    }

    if (portalType === 'Engagement Portal') {
      const newPortal: EngagementPortal = {
        id,
        type: 'Engagement Portal',
        name,
        urlSlug,
        color,
        entityLinks,
        targetsLink,
        managerIds: [],
        reportIds: [],
        dashboardIds: [],
        scorecardIds: [],
        createdOn: portal ? portal.createdOn : getTimeStamp(),
        updatedOn: getTimeStamp(),
        createdBy: portal ? portal.createdBy : currentUserId,
        updatedBy: currentUserId,
      };
      setDraftPortal(newPortal);
      return;
    }

    if (portalType === 'Bonus Portal') {
      if (!bonusProgramId || !defaultScreen) {
        setDraftPortal(undefined);
        return;
      }
      const newPortal: BonusPortal = {
        id,
        type: 'Bonus Portal',
        name,
        urlSlug,
        color,
        bonusProgramId,
        sideNavigationSections,
        bonusPeriodSignOffUserIds: [],
        readOnlyUserIds: [],
        createdOn: portal ? portal.createdOn : getTimeStamp(),
        updatedOn: getTimeStamp(),
        createdBy: portal ? portal.createdBy : currentUserId,
        updatedBy: currentUserId,
        defaultScreen,
      };
      setDraftPortal(newPortal);
    }
  }, [
    bonusProgramId,
    color,
    currentUserId,
    defaultScreen,
    entityLinks,
    id,
    isUrlSlugValid,
    name,
    portal,
    portalType,
    sideNavigationSections,
    targetsLink,
    urlSlug,
  ]);

  return {
    name,
    onNameChanged,
    portalType,
    portalTypeOptions,
    color,
    colorOptions,
    draftPortal,
    urlSlug,
    onUrlSlugChanged,
    isUrlSlugValid,
    targetsLink,
    setTargetsLink,
    entityLinks,
    setEntityLinks,
    bonusProgramId,
    setBonusProgramId,
    sideNavigationSections,
    setSideNavigationSections,
    defaultScreen,
    setDefaultScreen,
  };
};

export default useFormState;
