import React, { useCallback, useContext, useMemo, useState } from 'react';
import { BonusPortalContext } from '../../../../../contextProviders/BonusPortalProvider';
import Alert from '../../../../../kingpin/Alert';
import useSelectedDoc from '../TargetProgress/useSelectedDoc';
import {
  StatusIcon,
  TooltipExpectationSection,
} from '../../../../Grid/ScoringStatusCell';
import Button from '../../../../../kingpin/atoms/Button';
import Card from '../../../../../kingpin/atoms/Card';
import Loading from '../../../../Loading';
import ReactPortal from '../../../../ReactPortal';
import {
  getBonusHeaderAfterTitleDivId,
  getBonusHeaderBeforeOptionsDivId,
} from '../../HomeWidgetList/SectionListItem';
import usePopup from '../../../../../hooks/usePopup';
import Form from '../../../../../kingpin/forms/Form';
import FormHeader from '../../../../../kingpin/forms/FormHeader';
import FormContent from '../../../../../kingpin/forms/FormContent';
import Typography from '../../../../../kingpin/atoms/Typography';
import Inputs from '../../../../Inputs';
import Row from 'components/Common/Row';
import Modal, { ModalTransition } from '@atlaskit/modal-dialog';
import { STATUS_FIELDS_META_FIELD } from '../../../../../constants';
import BoardContext from '../../../../../contexts/BoardContext';
import DatasetDefinitionsContext from '../../../../../contexts/DatasetDefinitionsContext';
import { PortalsContext } from '../../../../../contextProviders/SplashScreenProviders/UserAndAccountProviders/PortalsProvider';
import portalTypeCheckers from '../../../../../types/portalTypeCheckers';
import PeriodsContext from '../../../../../contexts/PeriodsContext';

const OverrideConfirmationModal = ({
  close,
  elasticDocument,
  metaData,
  sectionTitle,
}: {
  close: () => void;
  elasticDocument: ElasticDocument;
  metaData: Scoring.StatusFieldDocumentMetaData;
  sectionTitle: string;
}) => {
  const bonusPortalContext = useContext(BonusPortalContext);
  const [note, setNote] = useState<string>('');
  const onConfirmClicked = useCallback(() => {
    if (!bonusPortalContext) {
      return;
    }

    bonusPortalContext.setDemoOverridenStatusFields((current) => {
      const docId = elasticDocument.id;
      const currentValue = metaData.passed;
      return {
        ...current,
        [docId]: !currentValue,
      };
    });
    close();
  }, [bonusPortalContext, close, elasticDocument.id, metaData.passed]);

  return (
    <Form>
      <FormHeader title={`Override ${sectionTitle}`} onClose={close} />
      <FormContent>
        <div style={{ marginBottom: 16 }}>
          <div style={{ marginBottom: 4 }}>
            <Typography.Body type={'Label'}>Note</Typography.Body>
          </div>
          <Inputs.TextArea
            value={note}
            onChange={(event) => {
              setNote(event.target.value);
            }}
          />
        </div>
      </FormContent>
      <Row spaceBetween centerAlign>
        <Button
          size={'Small'}
          type={'Secondary'}
          label={'Cancel'}
          onClick={close}
        />
        <Button
          size={'Small'}
          type={'Primary'}
          label={'Confirm'}
          onClick={onConfirmClicked}
        />
      </Row>
    </Form>
  );
};

const BonusCategories = ({
  section,
  elasticDocument,
  metaData,
  dataset,
}: {
  section: PerformanceBoardTypes.SlideOut.BonusCategorySection;
  elasticDocument: ElasticDocument;
  metaData: Scoring.StatusFieldDocumentMetaData;
  dataset: string;
}) => {
  const bonusPortalContext = useContext(BonusPortalContext);
  const { isOpen, open, close } = usePopup();

  return (
    <div>
      <ReactPortal elementId={getBonusHeaderAfterTitleDivId(section)}>
        <StatusIcon passed={metaData.passed} />
      </ReactPortal>
      {!!bonusPortalContext && (
        <ReactPortal elementId={getBonusHeaderBeforeOptionsDivId(section)}>
          <Button
            size={'Small'}
            type={'Ghost'}
            onClick={open}
            label="Override"
            icon="checkmark"
          />
        </ReactPortal>
      )}

      <div>
        {metaData.expectations.map((expectation) => (
          <Card
            key={expectation.field}
            style={{ marginBottom: 8, padding: 16 }}
          >
            <TooltipExpectationSection
              expectation={expectation}
              elasticDocument={elasticDocument}
              dataset={dataset}
            />
          </Card>
        ))}
      </div>
      <ModalTransition>
        {isOpen && (
          <Modal
            onClose={close}
            shouldScrollInViewport={false}
            autoFocus={false}
          >
            <OverrideConfirmationModal
              close={close}
              elasticDocument={elasticDocument}
              metaData={metaData}
              sectionTitle={section.title}
            />
          </Modal>
        )}
      </ModalTransition>
    </div>
  );
};

const BonusCategoriesProvider = ({
  section,
  dataset,
}: {
  section: PerformanceBoardTypes.SlideOut.BonusCategorySection;
  portal: BonusPortal;
  dataset: FleetOps.DatasetDefinition;
  bonusProgram: Scoring.BonusProgram;
  selectedBonusPeriod: Scoring.BonusPeriod;
}) => {
  const bonusPortalContext = useContext(BonusPortalContext);
  const { selectedDoc, isLoading } = useSelectedDoc();
  const metaData = useMemo(() => {
    if (!selectedDoc) {
      return undefined;
    }
    const statusFields = selectedDoc[STATUS_FIELDS_META_FIELD] as
      | {
          [field: string]: Scoring.StatusFieldDocumentMetaData;
        }
      | undefined;
    if (!statusFields) {
      return undefined;
    }

    const baseMetaData = statusFields[section.statusField];

    if (
      bonusPortalContext &&
      bonusPortalContext.demoOverridenStatusFields[selectedDoc.id] !== undefined
    ) {
      baseMetaData.passed =
        bonusPortalContext.demoOverridenStatusFields[selectedDoc.id];
    }

    return baseMetaData;
  }, [bonusPortalContext, section.statusField, selectedDoc]);

  if (!metaData || !selectedDoc || isLoading) {
    return <Loading />;
  }

  return (
    <BonusCategories
      dataset={dataset.type}
      metaData={metaData}
      section={section}
      elasticDocument={selectedDoc}
    />
  );
};

const InjectBonusPortalArgs = ({
  section,
}: {
  section: PerformanceBoardTypes.SlideOut.BonusCategorySection;
}) => {
  const { selectedPeriod } = useContext(PeriodsContext);
  const { board } = useContext(BoardContext);
  const { getBonusProgram, datasets, bonusPeriodsLookup } = useContext(
    DatasetDefinitionsContext,
  );
  const { availablePortals } = useContext(PortalsContext);

  const bonusProgram = useMemo(() => {
    const program = getBonusProgram(board.dataType);
    return program;
  }, [board.dataType, getBonusProgram]);

  const bonusPortal = useMemo(() => {
    if (!bonusProgram) {
      return;
    }

    const portal = availablePortals
      .filter(portalTypeCheckers.isBonusPortal)
      .find((p) => p.bonusProgramId === bonusProgram.id);

    return portal;
  }, [availablePortals, bonusProgram]);

  const dataset = useMemo(() => {
    return datasets.find((d) => d.type === board.dataType);
  }, [board.dataType, datasets]);

  const selectedBonusPeriod = useMemo(() => {
    if (!selectedPeriod || !bonusProgram) {
      return undefined;
    }

    const periods = bonusPeriodsLookup[bonusProgram.id];
    if (!periods) {
      return undefined;
    }

    return periods.find((p) => p.startDate === selectedPeriod.startDate);
  }, [bonusPeriodsLookup, bonusProgram, selectedPeriod]);

  if (!bonusPortal || !bonusProgram || !dataset || !selectedBonusPeriod) {
    return (
      <div>
        <Alert
          type={'Warning'}
          body={
            'This bonus portal associated with this program could not be found'
          }
        />
      </div>
    );
  }

  return (
    <BonusCategoriesProvider
      section={section}
      selectedBonusPeriod={selectedBonusPeriod}
      bonusProgram={bonusProgram}
      dataset={dataset}
      portal={bonusPortal}
    />
  );
};

const BonusCategoriesGate = ({
  section,
}: {
  section: PerformanceBoardTypes.SlideOut.BonusCategorySection;
}) => {
  const bonusPortalContext = useContext(BonusPortalContext);
  if (!bonusPortalContext) {
    return <InjectBonusPortalArgs section={section} />;
  }

  const { selectedBonusPeriod, bonusProgram, portal, dataset } =
    bonusPortalContext;

  return (
    <BonusCategoriesProvider
      section={section}
      selectedBonusPeriod={selectedBonusPeriod}
      bonusProgram={bonusProgram}
      dataset={dataset}
      portal={portal}
    />
  );
};

export default BonusCategoriesGate;
