import GridCellErrorBoundary from '../GridCellErrorBoundary';
import React, { useContext, useMemo } from 'react';
import { ICellRendererParams } from 'ag-grid-community';
import Cell from '../V5Gadget/Matrix/Cell';
import GridContext from '../../contexts/GridContext';
import DatasetDefinitionsContext from '../../contexts/DatasetDefinitionsContext';
import Icon from '../../kingpin/atoms/Icon';
import Colors2 from '../../theme/Colors2';
import BoardContext from '../../contexts/BoardContext';
import isPerformanceBoard from '../../isPerformanceBoard';
import Row from '../Common/Row';
import Typography from '../../kingpin/atoms/Typography';
import useGetFieldLabel from '../../hooks/useGetFieldLabel';
import useValueFormatters from '../../hooks/useValueFormatters';
import captureException from '../../services/captureException';
import Tooltip from '../Tooltip';
import { BonusPortalContext } from '../../contextProviders/BonusPortalProvider';
import { STATUS_FIELDS_META_FIELD } from '../../constants';

export const StatusIcon = ({ passed }: { passed: boolean }) => {
  if (passed) {
    return <Icon icon="checkmark" color={Colors2.Secondary.success} />;
  }

  return <Icon icon="cross" color={Colors2.Secondary.error} />;
};

export const TooltipExpectationSection = ({
  expectation,
  elasticDocument,
  dataset,
}: {
  expectation: Scoring.StatusFieldExpectation;
  elasticDocument: ElasticDocument;
  dataset: string;
}) => {
  const { getFieldLabel } = useGetFieldLabel();
  const { formatField } = useValueFormatters();

  const actual = useMemo(() => {
    try {
      const raw = elasticDocument[expectation.field];
      const formatted = formatField({
        field: expectation.field,
        value: raw,
        dataset,
      });

      return {
        raw,
        formatted,
      };
    } catch (ex) {
      const error = new Error();
      error.name = 'TooltipExpectation: Failed to format actual';
      captureException(error);
      return undefined;
    }
  }, [dataset, elasticDocument, expectation.field, formatField]);

  const expected = useMemo(() => {
    try {
      const raw = expectation.expectation;
      const formatted = formatField({
        field: expectation.field,
        value: raw,
        dataset,
      });

      return {
        raw,
        formatted,
      };
    } catch (ex) {
      const error = new Error();
      error.name = 'TooltipExpectation: Failed to format expectation';
      captureException(error);
      return undefined;
    }
  }, [dataset, expectation.expectation, expectation.field, formatField]);

  const fieldLabel = useMemo(() => {
    try {
      return getFieldLabel({ field: expectation.field, dataType: dataset });
    } catch (ex) {
      const error = new Error();
      error.name = 'TooltipExpectation: Failed to format field label';
      captureException(error);
      return expectation.field;
    }
  }, [dataset, expectation.field, getFieldLabel]);

  if (!actual || !expected) {
    return null;
  }

  return (
    <Row centerAlign spaceBetween>
      <Row centerAlign style={{ gap: 8, marginRight: 32 }}>
        <StatusIcon passed={expectation.passed} />
        <Typography.Body type={'Button Text'}>{fieldLabel}</Typography.Body>
      </Row>
      <Row style={{ justifyContent: 'flex-end' }}>
        <Typography.Body type={'Button Text'}>
          {actual.formatted}
        </Typography.Body>
        <div style={{ marginLeft: 4, marginRight: 4 }}>
          <Typography.Body type={'Button Text'}>/</Typography.Body>
        </div>
        <Typography.Body type={'Button Text'}>
          {expected.formatted}
        </Typography.Body>
      </Row>
    </Row>
  );
};

export const ScoringTooltipContent = ({
  elasticDocument,
  metaData,
  dataset,
}: {
  elasticDocument: ElasticDocument;
  metaData: Scoring.StatusFieldDocumentMetaData;
  dataset: string;
}) => {
  return (
    <div style={{ gap: 8 }}>
      {metaData.expectations.map((e) => (
        <TooltipExpectationSection
          key={e.field}
          dataset={dataset}
          elasticDocument={elasticDocument}
          expectation={e}
        />
      ))}
    </div>
  );
};

const ScoringStatusCell = ({
  bonusProgram,
  metaData,
  elasticDocument,
}: {
  bonusProgram: Scoring.BonusProgram;
  metaData: Scoring.StatusFieldDocumentMetaData;
  elasticDocument: ElasticDocument;
}) => {
  return (
    <Cell>
      <Tooltip
        isAltTooltip
        content={
          <ScoringTooltipContent
            elasticDocument={elasticDocument}
            dataset={bonusProgram.dataset}
            metaData={metaData}
          />
        }
      >
        <StatusIcon passed={metaData.passed} />
      </Tooltip>
    </Cell>
  );
};

const ScoringStatusCellProvider = (params: ICellRendererParams) => {
  const bonusPortalContext = useContext(BonusPortalContext);
  const { dataType } = useContext(GridContext);
  const { board } = useContext(BoardContext);
  const elasticDocument = useMemo(() => {
    return params.node.data as ElasticDocument;
  }, [params.node.data]);

  const currentDataType = useMemo(() => {
    if (dataType) {
      return dataType;
    }

    if (board && isPerformanceBoard(board)) {
      return board.dataType;
    }

    return undefined;
  }, [board, dataType]);

  const { getBonusProgram } = useContext(DatasetDefinitionsContext);
  const bonusProgram = useMemo(() => {
    if (!currentDataType) {
      return undefined;
    }
    return getBonusProgram(currentDataType);
  }, [currentDataType, getBonusProgram]);
  const metaData = useMemo(() => {
    const { colDef } = params;
    if (!colDef || !colDef.field) {
      return undefined;
    }
    const statusFields = elasticDocument[STATUS_FIELDS_META_FIELD] as
      | {
          [field: string]: Scoring.StatusFieldDocumentMetaData;
        }
      | undefined;
    if (!statusFields) {
      return undefined;
    }

    const baseMetaData = statusFields[colDef.field];

    if (
      bonusPortalContext &&
      bonusPortalContext.demoOverridenStatusFields[elasticDocument.id] !==
        undefined
    ) {
      baseMetaData.passed =
        bonusPortalContext.demoOverridenStatusFields[elasticDocument.id];
    }

    return baseMetaData;
  }, [bonusPortalContext, elasticDocument, params]);

  if (!bonusProgram || !metaData) {
    return <Cell />;
  }

  return (
    <ScoringStatusCell
      bonusProgram={bonusProgram}
      metaData={metaData}
      elasticDocument={elasticDocument}
    />
  );
};

const Gate = (params: ICellRendererParams) => (
  <GridCellErrorBoundary
    params={params}
    childComponent={<ScoringStatusCellProvider {...params} />}
  />
);

export default Gate;
