import firebase from 'firebase/compat/app';
import STORE_CONSTANTS from '../constants';
import withoutNulls from '../../api/search/withoutNulls';

const BONUS_PORTALS_DOC = 'bonusPortals';
const CURRENT_VERSION = 'v1';

const bonusPortalConverter = {
  toFirestore(app: BonusPortal): firebase.firestore.DocumentData {
    return withoutNulls({ ...app });
  },
  fromFirestore(
    snapshot: firebase.firestore.QueryDocumentSnapshot<BonusPortal>,
    options: firebase.firestore.SnapshotOptions,
  ): BonusPortal {
    const data: BonusPortal = {
      ...snapshot.data(options),
    };

    return data;
  },
};

const getBonusPortalsRef = ({
  accountId,
  db,
}: {
  accountId: string;
  db?: firebase.firestore.Firestore;
}) => {
  return (db ? db : firebase.firestore())
    .collection('accounts')
    .doc(accountId)
    .collection(STORE_CONSTANTS.CONTENT_DISTRIBUTIONS_COLLECTION)
    .doc(BONUS_PORTALS_DOC)
    .collection(CURRENT_VERSION)
    .withConverter(bonusPortalConverter);
};

export default getBonusPortalsRef;
