import React, { useContext } from 'react';
import Row from 'components/Common/Row';
import MainNavTop from '../MainNavTop';
import MainNavBottom from '../MainNavBottom';
import { MainNavDiv, MainNavPrimaryContent } from './PortalsMainNav';
import NavSideBarContext from '../../../contexts/NavSideBarContext';

const EngagementModeNav = () => {
  const { isOpen } = useContext(NavSideBarContext);

  return (
    <Row>
      <MainNavDiv isCollapsed={!isOpen}>
        <MainNavPrimaryContent>
          <MainNavTop />
        </MainNavPrimaryContent>
        <MainNavBottom />
      </MainNavDiv>
    </Row>
  );
};

export default EngagementModeNav;
