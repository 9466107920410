import React, { useContext } from 'react';
import { PortalsContext } from 'contextProviders/SplashScreenProviders/UserAndAccountProviders/PortalsProvider';
import { CurrentUserContentSettingsContext } from 'contextProviders/SplashScreenProviders/UserAndAccountProviders/CurrentUserContentSettingsProvider';

import LegacyMainNav from './LegacyMainNav';
import PortalsMainNav from './PortalsMainNav';
import EngagementModeNav from './EngagementModeNav';
import { AppsContext } from '../../../contextProviders/SplashScreenProviders/UserAndAccountProviders/AppsProvider';
import AppsMainNav from './AppsMainNav';

const MainNavContainer = () => {
  const { contentSettings } = useContext(CurrentUserContentSettingsContext);
  const { hasAppsEnabled } = useContext(AppsContext);
  const { isPortalsEnabled } = useContext(PortalsContext);

  if (!isPortalsEnabled) {
    return <LegacyMainNav />;
  }

  if (contentSettings.mode === 'engagement') {
    return <EngagementModeNav />;
  }

  if (hasAppsEnabled) {
    return <AppsMainNav />;
  }

  return <PortalsMainNav />;
};

export default MainNavContainer;
