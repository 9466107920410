import { useContext, useEffect, useMemo, useState } from 'react';
import STORE from 'store';
import { PortalsContext } from '../PortalsProvider';
import isDefined from '../../../../isDefined';
import AccountPickerContext from '../../../../contexts/AccountPickerContext';
import appTypeCheckers from './appTypeCheckers';
import AccountContext from '../../../../contexts/AccountContext';

const useApps = () => {
  const { hasAppsEnabled } = useContext(AccountContext);
  const { selectedAccountId } = useContext(AccountPickerContext);
  const [persistedApps, setPersistedApps] = useState<
    (Apps.PersistedGeneralApp | Apps.AnalyticsApp)[]
  >([]);
  const [isLoadingApps, setIsLoadingApps] = useState<boolean>(true);
  const { availablePortals, isLoadingPortals } = useContext(PortalsContext);

  useEffect(() => {
    if (!hasAppsEnabled) {
      setIsLoadingApps(false);
      setPersistedApps([]);
      return;
    }

    setIsLoadingApps(true);
    const l = STORE.contentDistributions
      .getAppsRef({
        accountId: selectedAccountId,
      })
      .onSnapshot((snapshot) => {
        const newApps: (Apps.PersistedGeneralApp | Apps.AnalyticsApp)[] = [];
        snapshot.forEach((app) => {
          newApps.push(app.data());
        });
        setPersistedApps(newApps);
        setIsLoadingApps(false);
      });
    return () => {
      l();
    };
  }, [hasAppsEnabled, selectedAccountId]);

  const apps = useMemo((): Apps.App[] => {
    return persistedApps
      .map((app) => {
        if (appTypeCheckers.isPersistedGeneralApp(app)) {
          return {
            ...app,
            portals: app.persistedPortals
              .map((p) => {
                return availablePortals.find(
                  (ap) => ap.id === p.typeId && ap.type === p.type,
                );
              })
              .filter(isDefined),
          };
        }

        if (appTypeCheckers.isAnalyticsApp(app)) {
          return {
            ...app,
          };
        }

        return undefined;
      })
      .filter(isDefined);
  }, [availablePortals, persistedApps]);

  return {
    hasAppsEnabled,
    apps,
    isLoading: isLoadingApps || isLoadingPortals,
  };
};

export default useApps;
